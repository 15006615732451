import React, { useState, useEffect } from "react";
// import Main from "./Main";
// import Dashboard from "./Dashboard";
// import Personal from "./Personal";
// import Records from "./Records";
// import Holiday from "./Holiday";
// import Qsr from "./Qsr";
// import Team from "./Team";
// import LeavePolicy from "./LeavePolicy";
import logo from "../Images/nbl_logo.png";
import { Container, Row, Modal } from "react-bootstrap";
import { FaUser, FaLock } from "react-icons/fa";
import Forgot from "../Images/forgot.png";
import jwt_decode from "jwt-decode";
import axios from "axios";
import swal from "sweetalert";

function Login() {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [loader, setLoader] = useState(false);
  const handleCallbackResponse = (response) => {
    console.log("jwt toekn", jwt_decode(response.credential));
    const responseData = jwt_decode(response.credential);
    var checkEmail = responseData.email.split("@");
    if (checkEmail[1] == "nablasol.com") {
      axios
        .post("/loginApi.php", responseData)
        .then(function (response) {
          const res = response.data;
          console.log(res.statusCode);
          if (res.statusCode === 200) {
            setUserData(res.result);
            localStorage.setItem("userId", res.result.userData.id);
            localStorage.setItem("userinfo", JSON.stringify(res.result));

            window.location.href = "/dashboard";
          } else {
            alert("Invalid username or password");
          }
        })
        .catch(function (error) {
          console.log(error);
          alert("Oops something went wrong");
        });
      setUserData(response.credential);
      localStorage.setItem("userId", responseData);
      localStorage.setItem("userinfo", JSON.stringify(responseData));

      //window.location.href = "/dashboard";
    } else {
      swal({
        icon: "https://stories.freepiklabs.com/storage/26838/oops-404-error-with-a-broken-robot-pana-2854.png",
        title: "Oops!",
        text: "Invalid Email! Try using Nablasol official email.",
        // icon: "error",
      });
    }
  };
  // useEffect(() => {
  //   // google
  //   window.google.accounts.id.initialize({
  //     client_id:
  //       "88810224893-vc4pknvih2m69mu1a2hvancjfpk9uuc1.apps.googleusercontent.com",
  //     callback: handleCallbackResponse,
  //   });
  //   google.accounts.id.renderButton(document.getElementById("signInDiv"), {
  //     theme: "outline",
  //     size: "large",
  //   });
  //   google.accounts.id.prompt();
  // }, []);
  const modalOpen = () => {
    setShow(true);
  };
  const modal2Open = () => {
    setShow2(true);
  };

  const modalClose = () => {
    setShow(false);
  };
  const modal2Close = () => {
    setShow2(false);
  };
  const [validity, setValidity] = useState(false);
  const [userData, setUserData] = useState({});
  const [reqPassword, setReqPassword] = useState({});
  const [newPassword, setNewPassword] = useState({});
  const [errors, setErrors] = useState({});

  // to set user data in state
  const setUserInfo = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };
  const setReqpasswordInfo = (e) => {
    setErrors({});
    setReqPassword({ ...reqPassword, [e.target.name]: e.target.value });
  };
  // to set user data in state
  const setNewpasswordInfo = (e) => {
    setErrors({});
    setNewPassword({ ...newPassword, [e.target.name]: e.target.value });
  };
  const loginForm = (e) => {
    e.preventDefault();

    // Make a request for a user with a given ID
    axios
      .post("/loginApi.php", userData)
      .then(function (response) {
        const res = response.data;
        console.log(res.statusCode);
        if (res.statusCode === 200) {
          setUserData(res.result);
          localStorage.setItem("userId", res.result.userData.id);
          localStorage.setItem("userinfo", JSON.stringify(res.result));

          window.location.href = "/dashboard";
        } else {
          alert("Invalid username or password");
        }
      })
      .catch(function (error) {
        console.log(error);
        alert("Oops something went wrong");
      });
  };
  const requestPassword = (e) => {
    e.preventDefault();
    console.log(reqPassword);
    // document.getElementById("req-pswd-btn").disabled = true;
    // setLoader(true);
    axios
      .post("/forgotPasswordApi.php", reqPassword)
      .then(function (response) {
        setValidity(true);
        const res = response.data.result.userData;
        setNewPassword({ ...newPassword, uId: res?.id });
        console.log(response.data.statusCode);
        if (res) {
          document.getElementById("req-pswd-btn").disabled = true;
          modal2Open();
          modalClose();
          // alert('Valid user');
        } else {
          setErrors({ dob: "Invalid DOB", pan: "Invalid Pan Number" });
          // alert('Invalid user');
        }
      })
      .catch(function (error) {
        console.log(error);
        setErrors({ dob: "Invalid DOB", pan: "Invalid Pan Number" });
      });
  };
  const resetPassword = (e) => {
    e.preventDefault();
    setLoader(true);
    if (newPassword.password === newPassword.cnfpassword) {
      axios
        .post("/changePasswordApi.php", newPassword)
        .then(function (response) {
          const res = response.data;
          if (res.statusCode === 200) {
            setNewPassword({ ...newPassword, success: "true" });
            document.getElementById("reset-pswd-btn").disabled = true;
            setLoader(false);
            window.location.reload();
          } else {
            alert("Oops something went wrong");
          }
          // console.log(res.statusCode);
        })
        .catch(function (error) {
          console.log(error);
          alert("Sorry. Something went wrong. Try Again.");
        });
    } else {
      setNewPassword({ ...newPassword, success: "false" });
      document.getElementById("reset-pswd-btn").disabled = false;
      setLoader(false);
      // alert("New password and confirm password do not match.");
    }
  };
  const validateFields = () => {
    if (!reqPassword.dob) {
      setErrors({ dob: "D.O.B is required." });
      return;
    }
    if (!reqPassword.pan) {
      setErrors({ pan: "Pan Number is required." });
      return;
    }
  };
  const validatePassword = () => {
    if (!newPassword.password) {
      setErrors({ password: "New Password is required" });
      return;
    }
    if (!newPassword.cnfpassword) {
      setErrors({ cnfpassword: "Confirm Password is required" });
      return;
    }
    if (newPassword.password != newPassword.cnfpassword) {
      setErrors({ cnfpassword: "Password and confirm password do not match." });
    }
  };
  return (
    <>
      <Modal
        show={show}
        onHide={modalClose}
        dialogClassName="request-password"
        aria-labelledby="Request Password"
        backdrop="static"
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            <div className="modal_title_wrapper">
              <img src={Forgot} />
              <div className="quote_sec">
                <h3>Reset Password</h3>
                <p>
                  Choosing a hard-to-guess but easy-to-remember password is
                  important!
                </p>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <hr className="hr_line" />
        <Modal.Body>
          <Container>
            <p> Please enter the below details to reset your password. </p>
            <Row className="mt-3">
              <form
                method="post"
                onSubmit={requestPassword}
                className="req-password-form"
                autoComplete="off"
              >
                <span className="question">
                  <label htmlFor="dob"> Date Of Birth</label>
                  <input
                    type="date"
                    name="dob"
                    className="d-block w-100 form-control"
                    required="required"
                    onChange={setReqpasswordInfo}
                  />
                </span>
                <label style={{ color: "red" }}>{errors.dob}</label>
                <span className="question mt-3">
                  <label htmlFor="pan"> PAN Number </label>
                  <input
                    type="text"
                    name="pan"
                    className="d-block w-100 form-control"
                    required="required"
                    onChange={setReqpasswordInfo}
                  />
                </span>
                <label style={{ color: "red" }}>{errors.pan}</label>
                <Modal.Footer>
                  <button
                    className="req-pswd nbl-btn"
                    id="req-pswd-btn"
                    type="submit"
                    onClick={() => {
                      validateFields();
                    }}
                  >
                    Reset Password
                  </button>
                </Modal.Footer>
              </form>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      <Modal
        show={show2}
        onHide={modal2Close}
        dialogClassName="reset-password"
        aria-labelledby="Reset Password"
        backdrop="static"
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            <div className="modal_title_wrapper">
              <img src={Forgot} />
              <div className="quote_sec">
                <h3>Reset Password</h3>
                <p>
                  Choosing a hard-to-guess but easy-to-remember password is
                  important!
                </p>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <hr className="hr_line" />
        <Modal.Body>
          <Container>
            <p> Please enter and confirm your new password. </p>
            <Row className="mt-3">
              <form
                method="post"
                onSubmit={resetPassword}
                className="req-password-form"
                autoComplete="off"
                //
              >
                <span className="question">
                  <label htmlFor="newPassword"> New Password</label>
                  <input
                    type="password"
                    name="password"
                    className="d-block w-100 form-control"
                    onChange={setNewpasswordInfo}
                    required="required"
                  />
                </span>
                <label style={{ color: "red" }}>{errors.password}</label>
                <span className="question mt-3">
                  <label htmlFor="confirmPassword"> Confirm Password </label>
                  <input
                    type="password"
                    name="cnfpassword"
                    className="d-block w-100 form-control"
                    onChange={setNewpasswordInfo}
                    required="required"
                  />
                </span>
                <label style={{ color: "red" }}>{errors.cnfpassword}</label>
                <Modal.Footer>
                  <button
                    className="req-pswd nbl-btn"
                    id="reset-pswd-btn"
                    type="submit"
                    onClick={validatePassword}
                  >
                    Reset Password
                  </button>
                </Modal.Footer>
              </form>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      <div>
        {/* <BrowserRouter> */}
        <div id="login">
          <div className="login-wrapper d-flex">
            <div className="login-form text-center">
              <div className="login_form_wrapper">
                <img src={logo} alt="nablasol logo" className="hrm-nbl-logo" />
                <h4 className="hrm-heading"> HRM </h4>
                <form method="post" onSubmit={loginForm}>
                  <div>
                    <label htmlFor="username">
                      <input
                        name="username"
                        type="text"
                        required
                        placeholder="Username"
                        className="form-control"
                        value={userData.username}
                        onChange={setUserInfo}
                        // onChange={(e) => setUsername(e.target.value)}
                      />
                      <span className="login-icons">
                        <FaUser />
                      </span>
                    </label>
                    <label htmlFor="password">
                      <input
                        name="password"
                        type="password"
                        placeholder="Password"
                        className="form-control"
                        required
                        value={userData.password}
                        onChange={setUserInfo}
                        //onChange={(e) => setPassword(e.target.value)}
                      />
                      <span className="login-icons">
                        <FaLock />
                      </span>
                      <a className="forgot-pswd" onClick={modalOpen}>
                        Forgot Password?
                      </a>
                    </label>
                  </div>
                  <input
                    type="submit"
                    value="Login"
                    className="btn login-submit"
                  />
                  {/* <div id="signInDiv"></div> */}
                </form>
              </div>
            </div>
            <div
              className="login-img"
              style={{ backgroundImage: `url("/img/HRM-login-page.png")` }}
            ></div>
          </div>
        </div>
        {/* <Routes>
            <Route
              exact
              path="/dashboard"
              element={<Dashboard name="dashboard" />}
            />
            <Route path="/employee-info" element={<Personal />} />
            <Route path="/main" element={<Main />} />
            <Route path="/records" element={<Records />} />
            <Route path="/holiday" element={<Holiday />} />
            <Route path="/quaterly-self-review" element={<Qsr />} />
            <Route path="/leave-policy" element={<LeavePolicy />} />
            <Route path="/team-members" element={<Team />} />
          </Routes>
        </BrowserRouter> */}
      </div>
    </>
  );
}

export default Login;
