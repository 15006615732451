import React, { useEffect, useState } from "react";
import ChatBubbleOutlinedIcon from "@mui/icons-material/ChatBubbleOutlined";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import CommentBox from "./CommentBox";
import axios from "axios";

const Card = ({ member_details, wishId }) => {
  const [postComment, setPostComment] = useState(false);
  const [showPostButton, setShowPostButton] = useState(false);
  const [comment, setComment] = useState([]);
  const [likePost, setLikePost] = useState(false);
  const [likeMembers, setLikeMembers] = useState([]);
  const [likeCount, setLikeCount] = useState(0);

  const likeID = localStorage.getItem("userId");

  // const fetchComments = async () => {
  //   const res = await axios.post(
  //     "https://hrmcrm.nablasol.net/custom/service/v4_1_custom/getBdayCommentApi.php",
  //     JSON.stringify({ wishId: "bc252032-a280-6516-ef91-63bd3c4ef795" })
  //   );
  //   setShowComment(res.data.result);
  // };
  const changeHandler = (e) => {
    const value = e.target.value;
    if (value !== "") {
      setShowPostButton(true);
    } else if (value == "") {
      setShowPostButton(false);
    }
    setComment(value);
  };

  const date = new Date(member_details.wishDet.date_entered);
  let countlike = likeCount;
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const likeHandler = (e) => {
    const likeCard = async () => {
      await axios.post(
        "https://hrmcrm.nablasol.net/custom/service/v4_1_custom/saveBdayLikeApi.php",
        JSON.stringify({
          wishId: wishId,
          likedUId: likeID,
        })
      );
      setLikePost(true);
      setLikeCount((prev) => prev + 1);
    };
    e.currentTarget.disabled = true;
    likeCard();
  };

  const fetchLikes = async () => {
    const res = await axios.post(
      "https://hrmcrm.nablasol.net/custom/service/v4_1_custom/getBdayLikeApi.php",
      JSON.stringify({
        wishId: wishId,
      })
    );

    setLikeMembers(res.data.result);
    setLikeCount(res.data.likeCounts);
  };

  const commentHandler = () => {
    const postHandler = async () => {
      const res = await axios.post(
        "https://hrmcrm.nablasol.net/custom/service/v4_1_custom/saveBdayCommentApi.php",
        JSON.stringify({
          wishId: wishId,
          commentedUId: likeID,
          comment,
        })
      );

      setShowPostButton(false);
    };
    postHandler();
  };
  useEffect(() => {
    fetchLikes();
  }, [countlike]);

  return (
    <div className="birthday-card">
      <div className="card-wrapper">
        <div className="top">
          <div className="avatar-box">
            <div className="avatar">
              <img
                src="https://www.nablasol.com/wp-content/uploads/2023/01/1-Nablasol-Final-Logo-Blue-1.png"
                alt=""
                className="profile-pic"
              />
            </div>
            <div className="profile-name">
              <h3>Nablasol</h3>
            </div>
          </div>
        </div>
        <div className="middle">
          <div className="bday-banner">
            <img src={member_details.wishDet.bdayimage_c} alt="" />
          </div>
        </div>
        <div className="bottom">
          <div className="icons">
            {likePost ? (
              <ThumbUpIcon className="icon liked-post" />
            ) : (
              <ThumbUpOutlinedIcon className="icon" onClick={likeHandler} />
            )}
            {likeCount}
            <ChatBubbleOutlinedIcon
              className="icon comment-icon"
              onClick={() => setPostComment(!postComment)}
            />
          </div>
          <div className="likedby">
            {likeCount > 0 && (
              <p>
                Liked by <strong>{`John and ${likeCount - 1} others`}</strong>
              </p>
            )}
            <article>{member_details.userDet.first_name}</article>
            <div className="time-stamp">
              <p>{date.toLocaleDateString("en-US", options)}</p>
            </div>
          </div>
          {postComment && (
            <div className="comment-box">
              <div className="input-comment-wrapper">
                <input
                  type="text"
                  placeholder="Post you comment ..."
                  onChange={changeHandler}
                />
                {showPostButton && (
                  <button onClick={commentHandler}>Post</button>
                )}
              </div>
              <div className="comments-box">
                <CommentBox
                  wishId={member_details.wishDet.id_c}
                  member_details={member_details}
                  comment={comment}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
