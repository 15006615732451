import "./App.css";
import React, { createContext, useState } from "react";
//import Main from "./components/Main";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import TaskMgmt from "./components/TaskMgmt";
import ProdTaskManagement from "./components/ProdTaskManagement";
import Personal from "./components/Personal";
import Team from "./components/Team";
import Records from "./components/Records";
import Holiday from "./components/Holiday";
import Qsr from "./components/Qsr";
import LeavePolicy from "./components/LeavePolicy";
import Terms from "./components/Terms";
import Error from "./components/Error";
import Clients from "./components/Clients";
import BdayWishes from "./components/BdayWishes";
import Reports from "./components/Reports"; 
import WorkReportDetails from "./components/WorkReportDetails";
import WorkReportAggregate from "./components/WorkReportAggregate";
import ClientTimesheet from "./components/ClientTimesheet";
import Employees from "./components/Employees";
import TrelloTasks from "./components/TrelloTasks";
import AllClients from "./components/AllClients";
import TrelloTasksClients from "./components/TrelloTasksClients";

import { Routes, Route } from "react-router-dom";
export const LoginManageContext = createContext();
function App() {
  const userInfo = JSON.parse(localStorage.getItem("userinfo"));
  const userId = localStorage.getItem("userId");
  const [loginData, setLoginData] = useState(userInfo);
  function updateData(response) {
    // console.log('update',response);
    setLoginData(response);
  }

  if (
    userId === null ||
    userId === 0 ||
    userId === "0" ||
    userId === undefined
  ) {
    return <Login />;
    //navigate('/');
  }
  return (
    <>
      <LoginManageContext.Provider
        value={{ loginData, setLoginData, updateData }}
      >
        <Routes>
          <Route exact path="/" element={<Login />} />

          <Route
            exact
            path="/dashboard"
            element={<Dashboard name="dashboard" />}
          />
          <Route
            exact
            path="/manage-tasks"
            element={<TaskMgmt name="tasks" />}
          />
          {/* {loginData?.userData?.companyemail ===
            "sourabh.patial@nablasol.com" ||
          loginData?.userData?.companyemail ===
            "dhirendra.kumar@nablasol.com" ||
          loginData?.userData?.companyemail === "gurpreet.modi@nablasol.com" ||
          loginData?.userData?.companyemail ===
            "manjeet.sharma@nablasol.com" ? (
            <Route
              exact
              path="/manage-prod-tasks"
              element={<ProdTaskManagement name="prod-tasks" />}
            />
          ) : null} */}
          <Route
            exact
            path="/employee-info"
            element={<Personal name="Personal" />}
          />
          <Route exact path="/team-members" element={<Team />} />
          <Route exact path="/records" element={<Records />} />
          <Route exact path="/holiday" element={<Holiday />} />
          <Route exact path="/quarterly-self-review" element={<Qsr />} />
          <Route exact path="/leave-policy" element={<LeavePolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route exact path={`/team-members/:userId`} element={<Clients />} />
          <Route path="*" element={<Error />} />
          <Route exact path="/bday-wishes" element={<BdayWishes />} />
          <Route exact path="/reports" element={<Reports />} />
          <Route exact path="/work-report-details" element={<WorkReportDetails />} />
          <Route exact path="/work-report-aggregate" element={<WorkReportAggregate />} />
          <Route exact path="/client-timesheet" element={<ClientTimesheet />} />
          <Route exact path="/trello-tasks-employee" element={<Employees />} />
          <Route exact path={`/trello-tasks/:userId`} element={<TrelloTasks />} />
          <Route exact path="/trello-tasks-clients" element={<AllClients />} />
          <Route exact path={`/trello-tasks-client/:clientId`} element={<TrelloTasksClients />} />
        </Routes>
      </LoginManageContext.Provider>
    </>
  );
}

export default App;
