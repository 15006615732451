import React, { useState, useEffect, useContext } from "react";
import Header, { getDateFormate } from "./Header";
import { Modal } from "react-bootstrap";
import Alert from "./Alert";
//import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import Sidebar from "./Sidebar";
import axios from "axios";
import { LoginManageContext } from "./../App";
import Issue from '../Images/issue.png';
function Records() {
  const [punchInOutRecord, setPunchInOutRecord] = useState({});
  const [raiseRequest, setRaiseRequest] = useState({});
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const { loginData } = useContext(LoginManageContext);
  //var userData = loginData.userData;
  var stateInfo = loginData;
  const uId = localStorage.getItem("userId");
  const name =
    stateInfo.userData.first_name + " " + stateInfo.userData.last_name;
  const email = stateInfo.userData.companyemail;
  const modalOpen = () => {
    setShow(true);
  };

  const modalClose = () => {
    setShow(false);
  };

  // set value for raise request
  const setVal = (e) => {
    setRaiseRequest({
      ...raiseRequest,
      [e.target.name]: e.target.value,
      uId: uId,
      name: name,
      email: email,
    });
  };

  const [alertmsg, setAlertMsg] = useState({
    msg: "",
    errorStatus: "",
    alertText: "",
    alertApplyText: "",
  });

  const getAllWeaklyReports = () => {
    axios
      .post("/getAllWeeklyReportsApi.php", { u_id: uId })
      .then(function (response) {
        const res = response.data;

        if (res.statusCode === 200) {
          setPunchInOutRecord(res.result);
        } else {
          console.log("Oops something went wrong");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // submit raise request
  const raiseRequestMethod = (e) => {
    e.preventDefault();
    document.getElementById("raise-ticket-submit").disabled = true;
    setLoader(true);

    axios
      .post("/raiseRequestApi.php", raiseRequest)
      .then(function (response) {
        const res = response.data;

        if (res.statusCode === 200) {
          // alert("Message successfully sent");
          setAlertMsg({
            ...alertmsg,
            msg: "Ticket Raised Successfully.",
            errorStatus: "success",
            alertApplyText: "on",
          });
          setShow(false);
          document.getElementById("raise-ticket-submit").disabled = false;
          setLoader(false);
          //setPunchInOutRecord(res.result);
        } else {
          setAlertMsg({
            ...alertmsg,
            msg: "Error Raising Ticket.",
            errorStatus: "error",
            alertText: "on",
          });
          console.log("Oops something went wrong");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllWeaklyReports();
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="main">
        <Sidebar />
        <div id="primary">
          <Header />
          <div id="records" className="main-view">
            <div className="view-head">
              <h6> Records </h6>
              <button className="nbl-btn" onClick={modalOpen}>
                Raise Ticket
              </button>

              <Modal
                show={show}
                onHide={modalClose}
                dialogClassName="raise-ticket"
                aria-labelledby="Add Trello Task"
                backdrop="static"
                size="md"
              >
                <Modal.Header closeButton>
                  <Modal.Title as="h5">
                    <div className="modal_title_wrapper">
                      <img src={Issue}/>
                      <div className="quote_sec">
                        <h3>Raise Ticket</h3>
                        <p>Better three hours too soon than a minute too late.</p>
                      </div>
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <hr className="hr_line "/>
                {loader === true ? (
                  <div className="loader">
                    <img src="/img/hrm-loader.svg" alt="loader" />
                  </div>
                ) : (
                  ""
                )}

                {alertmsg.alertApplyText == "on" ? (
                  <Alert alertText={alertmsg} />
                ) : (
                  ""
                )}

                <Modal.Body>
                  <form method="post" onSubmit={raiseRequestMethod}>
                    {/* <label>Description : </label> */}
                    <div className="request-desc">
                      <div className="ticket_desc">
                        <label>Description</label>
                        <textarea
                          type="textarea"
                          rows="3"
                          placeholder=""
                          name="description"
                          required
                          onChange={setVal}
                        />
                      </div>
                    </div>
                    <div className="request_submit_button">
                      <input
                        type="submit"
                        value="Submit"
                        className="btn login-submit"
                        id="raise-ticket-submit"
                      />
                    </div>
                  </form>
                </Modal.Body>
              </Modal>
            </div>
            <div className="view-grid grid-dashlet">
              <div class="records-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th> Punch In Date </th>
                      <th> Punch In Time </th>
                      <th> Punch Out Date </th>
                      <th> Punch Out Time </th>
                      <th> Duration </th>
                    </tr>
                  </thead>
                  <tbody>
                    {punchInOutRecord.length > 0
                      ? punchInOutRecord.map((row, index) => (
                          <tr>
                            <td>
                              {" "}
                              {getDateFormate(
                                row.punchin_time_c,
                                "",
                                "",
                                true
                              )}{" "}
                            </td>
                            <td>
                              {" "}
                              {getDateFormate(
                                row.punchin_time_c,
                                "",
                                "",
                                "",
                                true
                              )}
                            </td>
                            <td>
                              {row.punchout_time_c === null ||
                              row.punchout_time_c === "0000-00-00 00:00:00" ||
                              row.punchout_time_c === row.punchin_time_c
                                ? "0000-00-00"
                                : getDateFormate(
                                    row.punchout_time_c,
                                    "",
                                    "",
                                    true
                                  )}{" "}
                            </td>
                            <td>
                              {row.punchout_time_c === null ||
                              row.punchout_time_c === "0000-00-00 00:00:00" ||
                              row.punchout_time_c === row.punchin_time_c
                                ? "00:00:00"
                                : getDateFormate(
                                    row.punchout_time_c,
                                    "",
                                    "",
                                    "",
                                    true
                                  )}
                            </td>
                            <td> {row.total_hours_c}</td>
                          </tr>
                        ))
                      : ""}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Records;
