import React, { useState, useEffect, useContext } from "react";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
import SelectSearch from "react-select-search";
import Select from "react-select";

import Sidebar from "./Sidebar";
import Header from "./Header";
import axios from "axios";
import { LoginManageContext } from "./../App";
import { VscSaveAs } from "react-icons/vsc";
import { Container, Row, Modal, Alert } from "react-bootstrap";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { array } from "prop-types";
import Task from "../Images/task.png";
function ProdTaskManagement() {
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  //const [newlabels, setNewlabels] = useState({});
  const [client, setClient] = useState({});
  const [board, setBoard] = useState({});
  const [displayBoard, setDisplayBoard] = useState({});
  const [list, setList] = useState({});
  const [displayCard, setDisplayCard] = useState({});
  const [displayTodayWork, setDisplayTodayWork] = useState({});
  const [labelListOptions, setlabelListOptions] = useState({});
  const [boardDisabled, setBoardDisabled] = useState(true);
  const [listDisabled, setListDisabled] = useState(true);

  //var allClients = [];
  const [listHide, setListHide] = useState(true);
  // var aquaticCreatures = "";
  //var listHide=true;
  // var mylabel= [
  //   {
  //     value: "5e9b1be5431c7b554df5fe3a",
  //     // idBoard: "5e9b1be465dbb582e24c7c37",
  //     label: "Fixed and updated",
  //    // color: "green",
  //   },
  //   {
  //     value: "5e9b1be87631ee6070143cd0",
  //    // idBoard: "5e9b1be465dbb582e24c7c37",
  //    // name: "Urgent",
  //    label: "red",
  //   }

  // ];
  const mylabel = [
    {
      label: "Fixed and updated",
      value:
        "e9b1be5431c7b554df5fe3a" +
        "_" +
        "Fixed and updated" +
        "_" +
        "5e9b1be465dbb582e24c7c37",
    },
    {
      label: "Urgent",
      value:
        "e9b1be5431c7b554df5fe3a" +
        "_" +
        "Fixed and updated" +
        "_" +
        "5e9b1be465dbb582e24c7c37",
    },
    { label: "Electronics & Computers", value: 3 },
    { label: "Home, Garden & Tools", value: 4 },
    { label: "Health & Beauty", value: 5 },
    { label: "Toys, Kids & Baby", value: 6 },
    { label: "Clothing & Jewelry", value: 7 },
    { label: "Sports & Outdoors", value: 8 },
    { label: "Automotive & Industrial", value: 9 },
  ];
  const selected = [
    {
      label: "Fixed and updated",
      value:
        "e9b1be5431c7b554df5fe3a" +
        "_" +
        "Fixed and updated" +
        "_" +
        "5e9b1be465dbb582e24c7c37",
    },
  ];
  var addrowData = {
    label_list: [
      {
        id: "5e9b1be5431c7b554df5fe3a",
        idBoard: "5e9b1be465dbb582e24c7c37",
        name: "Fixed and updated",
        color: "green",
      },
      {
        id: "5e9b1be87631ee6070143cd0",
        idBoard: "5e9b1be465dbb582e24c7c37",
        name: "Urgent",
        color: "red",
      },
      {
        id: "6164396a9e510d40a1c4f6e6",
        idBoard: "5e9b1be465dbb582e24c7c37",
        name: "Completed",
        color: "lime",
      },
      {
        id: "5e9b1be9ccbb6a66e34a173d",
        idBoard: "5e9b1be465dbb582e24c7c37",
        name: "To Do",
        color: "purple",
      },
      {
        id: "5e9b1be76c076066c2977ee0",
        idBoard: "5e9b1be465dbb582e24c7c37",
        name: "Immediate",
        color: "orange",
      },
      {
        id: "5f4d0321e97f426c7ecd94d3",
        idBoard: "5e9b1be465dbb582e24c7c37",
        name: "More info needed",
        color: "sky",
      },
      {
        id: "6128cae9da5c73891ecb3e5e",
        idBoard: "5e9b1be465dbb582e24c7c37",
        name: "Work In Progress",
        color: "lime",
      },
      {
        id: "5e9b1beac3ea9505bde945f4",
        idBoard: "5e9b1be465dbb582e24c7c37",
        name: "R & D",
        color: "blue",
      },
      {
        id: "5e9b1be6b0f95c604500ac4a",
        idBoard: "5e9b1be465dbb582e24c7c37",
        name: "Fixed pending for update",
        color: "yellow",
      },
      {
        id: "617fc9633dba7c8ac1c32ba3",
        idBoard: "5e9b1be465dbb582e24c7c37",
        name: "Hold",
        color: "yellow",
      },
      {
        id: "5f92b6847393473f7690c971",
        idBoard: "5e9b1be465dbb582e24c7c37",
        name: "Depends on another task",
        color: "black",
      },
      {
        id: "61a5d7aa24b8063e9fd5fea0",
        idBoard: "5e9b1be465dbb582e24c7c37",
        name: "Didn't get any response",
        color: "orange",
      },
      {
        id: "61d40c03275b3a3c65fc3e21",
        idBoard: "5e9b1be465dbb582e24c7c37",
        name: "Ready for review",
        color: "lime",
      },
      {
        id: "620f9138f6288d589a1969ce",
        idBoard: "5e9b1be465dbb582e24c7c37",
        name: "Waiting for review",
        color: "pink",
      },
    ],
    id: "1",
    idList: "12345",
    name: "manual",
    taskId: "",
    time: "",
    description: "",
    comment_id: "",
    labels: [],
  };

  //   const [commentInfo, setCommenInfo] = useState([    {'id': "5f466b0bfed50753c1b1d2d1",'idList': "12345",
  //   'name': "others",
  //   'taskId': "",
  //   'time': "",
  //   'description': "",
  //   'comment_id': ""
  //  }]);
  // var i = 0;
  const [trelloInfo, setTrelloInfo] = useState({});
  const { loginData } = useContext(LoginManageContext);
  //var userData = loginData.userData;
  var stateInfo = loginData;
  const uId = localStorage.getItem("userId");

  // const addValues = {};
  const setValue = (e) => {
    setDisplayCard("");
    setLoader(true);
    setTimeout(() => {
      const boardResponse = e.value.split("_");
      const boardId = boardResponse[0];
      //setList({ mySelectKey: null });
      if (boardResponse[1] === "manual") {
        setListHide(false);

        var aaa = new Array();
        aaa.push(addrowData);
        setDisplayCard(aaa);
        // $('#time_1').val('');
        // $('#descp_1').val('');
        // document.getElementById("descp_1").value=' ';
      } else {
        setListHide(true);

        boardList(boardId);
      }
      setTrelloInfo({ ...trelloInfo, boardId: e.value });
      setLoader(false);
      setListDisabled(false);
    }, 500);
  };

  const modalOpen = () => {
    setShow(true);
  };

  const modalClose = () => {
    setShow(false);
  };

  // get

  const getListName = (listId) => {
    for (var i = 0; i < list.length; i++) {
      if (list[i].id === listId) {
        return list[i].name;
      } //return arr[i][key];
    }
  };
  const getBoard = (e) => {
    setDisplayCard("");
    setLoader(true);
    setListDisabled(true);
    setTimeout(() => {
      // $("#c_board .css-1uccc91-singleValue").text("Select Board");
      // $("#c_task .css-1uccc91-singleValue").text("Select List");

      var allBoard = [];
      setListHide(true);
      //setDisplayCard({});
      //const value = new Array();
      // setList({ mySelectKey: null });
      // setDisplayBoard({ mySelectKey: null });
      setTrelloInfo({ ...trelloInfo, clientId: e.value });
      const clientResponse = e.value.split("_");
      const clinetId = clientResponse[0];
      console.log("clinetId", clinetId);
      for (var i = 0; i < board.length; i++) {
        console.log("board[i].client_id", board[i].client_id);
        if (board[i].client_id === clinetId) {
          var allBoardData = {
            label: board[i].name,
            value:
              board[i].board_id_c +
              "_" +
              board[i].name +
              "_" +
              board[i].board_crm_id,
          };
          allBoard.push(allBoardData);
        }
      }

      allBoard.push({
        label: "Manual",
        value: "_manual_15152ac2-8c9a-f00c-59ac-6217286bb7bb",
      });
      console.log("allBoard", allBoard);
      setDisplayBoard(allBoard);
      //boardList():
      setLoader(false);
      setBoardDisabled(false);
      setList("");
    }, 500);
  };

  const getAllClient = () => {
    var allClients = [];
    axios.post("/getAllClientsApi.php", { uId: uId }).then(function (response) {
      const res = response.data;
      if (response.status === 200 && res.result !== null && response !== null) {
        var i = 0;
        for (i = 0; i < res.result.length; i++) {
          var allClientsData = {
            label: res.result[i].client_c,
            value: res.result[i].id + "_" + res.result[i].client_c,
          };
          allClients.push(allClientsData);
        }
        setClient(allClients);
      } else {
        console.log("Oops someting went wrong");
      }
    });
  };
  const getAllBoard = () => {
    axios.post("/getAllBoardsApi.php", { uId: uId }).then(function (response) {
      const res = response.data;
      if (response.status === 200 && res.result !== null && response !== null) {
        setBoard(res.result);
      } else {
        console.log("Oops someting went wrong");
      }
    });
  };
  const boardList = (boardId) => {
    var allList = [];
    axios
      .post("/boardListApi.php", {
        boardId: boardId,
        uId: uId,
        key: stateInfo.userData.trello_user_key,
        token: stateInfo.userData.trello_token,
      })
      .then(function (response) {
        const res = response.data;
        if (response.status === 200 && res.result !== null) {
          var i = 0;
          for (i = 0; i < res.result.length; i++) {
            if (
              res.result[i].name.toLowerCase().startsWith("in production") ||
              res.result[i].name.toLowerCase().startsWith("production")
            ) {
              var allListData = {
                label: res.result[i].name,
                value: res.result[i].id,
              };
              allList.push(allListData);
            }
          }
          //setClient(allClients);
          setList(allList);
        } else {
          console.log("Oops someting went wrong");
        }
      });
  };
  const getCardList = (e) => {
    setDisplayCard("");
    setLoader(true);
    const boardId = trelloInfo.boardId.split("_")[0];
    const boardcrmId = trelloInfo.boardId.split("_")[2];
    const listId = e.value.split("_")[0];

    axios
      .post("/allCardsOfListApi.php", {
        listId: listId,
        uId: uId,
        boardTrelloId: boardId,
        boardcrmId: boardcrmId,
        key: stateInfo.userData.trello_user_key,
        token: stateInfo.userData.trello_token,
        trelloId: stateInfo.userData.trello_user_id,
      })
      .then(function (response) {
        const res = response.data;
        if (response.status === 200 && res.result !== null) {
          if (boardcrmId === "15152ac2-8c9a-f00c-59ac-6217286bb7bb") {
            var jsons = new Array();
            jsons.push(addrowData);
            var i = 0;
            for (i = 0; i < res.result.length; i++) {
              jsons.push(res.result[i]);
            }
          } else {
            var jsons = res.result;
          }
          setlabelListOptions(res.label_list);
          setDisplayCard(jsons);
        } else {
          setDisplayCard("");
        }
        setLoader(false);
      });
    //}
  };

  const setHr = (e) => {
    const hrs = e.target.value;
    const textId = e.target.id;
    if (isNaN(hrs)) {
      document.getElementById(textId).value = 0;
    }
    if (hrs.match(/^00/)) {
      document.getElementById(textId).value = 0;
    }
    if (hrs > 9) {
      document.getElementById(textId).value = hrs.substring(0, hrs.length - 1);
    }
    if (hrs.indexOf(".") > -1 && hrs.split(".")[1].length > 1) {
      document.getElementById(textId).value = hrs.substring(0, hrs.length - 1);
    }
  };
  const saveComment = (id) => {
    console.log("id", id);
    var dataInfo = new Array();
    var title = "";
    if (id != 1) {
      var oldlabelsLength = Object.keys(
        document.getElementById("oldlabels_" + id).value
      ).length;
      if (oldlabelsLength > 0) {
        var oldlabels = JSON.parse(
          document.getElementById("oldlabels_" + id).value
        );
      } else {
        var oldlabels = [];
      }
      var newlabelsLength = Object.keys(
        document.getElementById("newlabels_" + id).value
      ).length;
      if (newlabelsLength > 0) {
        var newlabels = JSON.parse(
          document.getElementById("newlabels_" + id).value
        );
      } else {
        var newlabels = [];
      }
    } else {
      title = document.getElementById("title_" + id).value;
      var oldlabels = [];
      var newlabels = [];
    }

    const clientId = document.getElementById("client_" + id).value;
    const boardId = document.getElementById("boardId_" + id).value;
    const listId = document.getElementById("listId_" + id).value;
    const listName = document.getElementById("listName_" + id).value;
    const cardInfoArray = document.getElementById("cardInfo_" + id).value;
    const cardInfoRes = cardInfoArray.split("_");
    const cardId = cardInfoRes[0];
    const cardName = cardInfoRes[1];
    const desc = document.getElementById("descp_" + id).value;
    const time = document.getElementById("time_" + id).value;
    if (time == "") {
      alert("Please enter a valid time");
      return false;
    }
    if (desc == "") {
      alert("Please enter a description");
      return false;
    }

    // labelRes = labelRes.split("_");
    // const labelId = labelRes[0];
    // const labelName = labelRes[1];
    // const trelloBoardId = labelRes[2];

    // valiadtion

    dataInfo.push({
      uId: uId,
      client_id: clientId,
      board_id: boardId,
      list_id: listId,
      list_name: listName,
      card_id: cardId,
      card_name: cardName,
      manual_task: "",
      task_desc: desc,
      hours: time,
      key: stateInfo.userData.trello_user_key,
      token: stateInfo.userData.trello_token,
      trelloId: stateInfo.userData.trello_user_id,
      // label_id: labelId,
      //label_name: labelName,
      //boardTrelloId: trelloBoardId,
      oldlabels: oldlabels,
      newlabels: newlabels,
      title: title,
    });
    axios
      .post("/createTaskApi.php", { all_param: dataInfo })
      .then(function (response) {
        const res = response.data;
        if (response.status === 200) {
          getAllTaskDoneByToday();
          if (id == 1) {
            document.getElementById("title_1").value = "";
            document.getElementById("time_1").value = "";
            document.getElementById("descp_1").value = "";
          }
          if (res.result != null) {
            if (boardId === "15152ac2-8c9a-f00c-59ac-6217286bb7bb") {
              var jsons = new Array();
              jsons.push(addrowData);
              var i = 0;
              for (i = 0; i < res.result.length; i++) {
                jsons.push(res.result[i]);
              }
            } else {
              var jsons = res.result;
            }
            alert("You have successfully commented on Trello");
            setDisplayCard(jsons);
          }
        } else {
          console.log("Oops someting went wrong");
        }
      });

    // const desc=document.getElementById('descp_'+id).value;
    //alert(clientId);
  };
  const updateComment = (id) => {
    //alert(id);
    var dataInfo = new Array();
    var title = "";
    // if (id != 1) {
    //   var oldlabels = JSON.parse(
    //     document.getElementById("oldlabels_" + id).value
    //   );
    //   var newlabels = JSON.parse(
    //     document.getElementById("newlabels_" + id).value
    //   );
    // } else {
    //   title = document.getElementById("title_" + id).value;
    //   var oldlabels = [];
    //   var newlabels = [];
    // }
    const clientId = document.getElementById("client_" + id).value;
    const boardId = document.getElementById("boardId_" + id).value;
    const listId = document.getElementById("listId_" + id).value;
    const listName = document.getElementById("listName_" + id).value;
    const cardInfoArray = document.getElementById("cardInfo_" + id).value;
    const cardInfoRes = cardInfoArray.split("_");
    const cardId = cardInfoRes[0];
    const cardName = cardInfoRes[1];
    const desc = document.getElementById("descp_" + id).value;
    const time = document.getElementById("time_" + id).value;
    const taskId = document.getElementById("taskId_" + id).value;
    const commentId = document.getElementById("commentId_" + id).value;
    if (id != 1) {
      if (cardName === "manual") {
        var oldlabels = [];
        var newlabels = [];
      } else {
        var oldlabelsLength = Object.keys(
          document.getElementById("oldlabels_" + id).value
        ).length;
        if (oldlabelsLength > 0) {
          var oldlabels = JSON.parse(
            document.getElementById("oldlabels_" + id).value
          );
        } else {
          var oldlabels = [];
        }
        var newlabelsLength = Object.keys(
          document.getElementById("newlabels_" + id).value
        ).length;
        if (newlabelsLength > 0) {
          var newlabels = JSON.parse(
            document.getElementById("newlabels_" + id).value
          );
        } else {
          var newlabels = [];
        }
      }
    } else {
      title = document.getElementById("title_" + id).value;
      var oldlabels = [];
      var newlabels = [];
    }

    if (time == "") {
      alert("Please enter a valid time");
      return false;
    }
    if (desc == "") {
      alert("Please enter a description");
      return false;
    }
    dataInfo.push({
      id: taskId,
      uId: uId,
      client_id: clientId,
      board_id: boardId,
      list_id: listId,
      list_name: listName,
      card_id: cardId,
      card_name: cardName,
      manual_task: "",
      task_desc: desc,
      comment_id: commentId,
      hours: time,
      key: stateInfo.userData.trello_user_key,
      token: stateInfo.userData.trello_token,
      trelloId: stateInfo.userData.trello_user_id,
      // label_id: labelId,
      // label_name: labelName,
      // boardTrelloId: trelloBoardId,
      oldlabels: oldlabels,
      newlabels: newlabels,
      title: title,
    });
    axios
      .post("/updateTaskApi.php", { all_param: dataInfo })
      .then(function (response) {
        const res = response.data;
        if (response.status === 200) {
          getAllTaskDoneByToday();
          if (res.result !== null) {
            if (boardId === "15152ac2-8c9a-f00c-59ac-6217286bb7bb") {
              var jsons = new Array();
              jsons.push(addrowData);
              var i = 0;
              for (i = 0; i < res.result.length; i++) {
                jsons.push(res.result[i]);
              }
            } else {
              var jsons = res.result;
              // for (i = 0; i < res.result.length; i++) {
              //   setlabelListOptions(res.result[i].label_list);
              // }
            }
            alert("You have successfully updated comment on Trello");

            setDisplayCard(jsons);
          }
        } else {
          console.log("Oops someting went wrong");
        }
      });
  };

  // set new label to add or update in trello ticket
  const setNewlabels = (id, e) => {
    console.log(e);
    // const newlabelsArray = new Array();
    // for (var i = 0; i < e.length; i++) {
    //   newlabelsArray.push(e[i].value);
    // }
    document.getElementById("newlabels_" + id).value = JSON.stringify(e);
  };
  // get all task that done by today
  const getAllTaskDoneByToday = () => {
    axios
      .post("/getAllTaskDoneByTodayApi.php", {
        uId: uId,
      })
      .then(function (response) {
        const res = response.data;
        if (response.status === 200 && res.result !== null) {
          setDisplayTodayWork(res.result);
        } else {
          console.log("Oops someting went wrong");
        }
      });
  };

  useEffect(() => {
    getAllBoard();
    getAllClient();
    getAllTaskDoneByToday();
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Modal
        show={show}
        onHide={modalClose}
        dialogClassName="add-trello-task"
        aria-labelledby="Add Trello Task"
        backdrop="static"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            <div className="modal_title_wrapper">
              <img src={Task} />
              <div className="quote_sec">
                <h3>Trello Tasks Updated</h3>
                <p>
                  You have the best combination of talent, skills, and attitude
                  that add up to great performance. <br /> Keep up the good
                  work!
                </p>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="mt-3">
              <div className="trello-task-tbl">
                {displayTodayWork.length > 0 ? (
                  <table>
                    <thead>
                      <tr>
                        <th className="client"> Client </th>
                        <th className="board"> Board </th>
                        <th className="task"> Task </th>
                        <th className="hr"> Hrs </th>
                        <th className="desc"> Description </th>
                        {/* <th className="trello-label"> Trello Label </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {displayTodayWork.length > 0
                        ? displayTodayWork.map((row, index) => (
                            <tr>
                              <td>{row.client}</td>
                              <td>
                                {row.manual_task_c != ""
                                  ? row.card_name_c.charAt(0).toUpperCase() +
                                    row.card_name_c.slice(1).toLowerCase()
                                  : row.board_name.charAt(0).toUpperCase() +
                                    row.board_name.slice(1).toLowerCase()}
                              </td>
                              <td>
                                {row.manual_task_c != ""
                                  ? row.manual_task_c.charAt(0).toUpperCase() +
                                    row.manual_task_c.slice(1).toLowerCase()
                                  : row.card_name_c}
                              </td>
                              <td>{row.hours_c}</td>
                              <td
                                className="trello-updated-descp"
                                style={{
                                  textAlign: "left",
                                }}
                              >
                                {row.taskdescription_c}
                              </td>
                              {/* <td>{row.trello_label}</td> */}
                            </tr>
                          ))
                        : ""}
                    </tbody>
                  </table>
                ) : (
                  <div className="norecords-display">No Tasks Updated Yet</div>
                )}
              </div>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      <div className="main">
        <Sidebar />
        <div id="primary">
          <Header />
          <div id="manage-tasks" className="main-view">
            <div className="view-head">
              <h6> Manage Tasks </h6>
              <button className="nbl-btn" onClick={modalOpen}>
                Tasks Updated
              </button>
            </div>
            <form action="" name="task-management" method="get">
              <div className="task-filter">
                <Select
                  id="clientId"
                  placeholder="Select Client"
                  onChange={getBoard}
                  name="clientId"
                  className="form-control filter"
                  options={client}
                />
                <Select
                  id="c_board"
                  placeholder="Select Board"
                  onChange={setValue}
                  name="boardId"
                  className="form-control filter"
                  options={displayBoard}
                  isDisabled={boardDisabled}
                />

                {listHide == true ? (
                  <Select
                    id="c_task"
                    name="taskId"
                    placeholder="Select List"
                    onChange={getCardList}
                    className="form-control filter"
                    options={list}
                    isDisabled={listDisabled}
                  />
                ) : (
                  ""
                )}
              </div>
            </form>

            {loader === true ? (
              <div className="loader" id="hrm-loader">
                <img src="/img/hrm-loader.svg" alt="loader" />
              </div>
            ) : (
              ""
            )}

            <div className="grid-dashlet">
              <table>
                <thead>
                  <tr>
                    {/* <th className="client"> Client </th>
                    <th className="board"> Board </th> */}
                    <th className="task"> Task </th>
                    <th className="hr"> Hrs </th>
                    <th className="desc"> Description </th>
                    {displayCard.length > 0 &&
                    displayCard.length !== "" &&
                    displayCard.length !== null &&
                    displayCard[0].id != 1 ? (
                      <th className="trello-label"> Trello Label </th>
                    ) : (
                      ""
                    )}
                    <th className="task-action"> Action </th>
                  </tr>
                </thead>
                <tbody>
                  {displayCard.length > 0 &&
                  displayCard.length !== "" &&
                  displayCard.length !== null ? (
                    displayCard.map((row, index) => (
                      <tr>
                        {/* <td> {trelloInfo.clientId.split("_")[1]} </td> */}

                        {/* <td> {trelloInfo.boardId.split("_")[1]} </td> */}
                        <td>
                          {row.name == "manual" ? (
                            <input
                              type="text"
                              key={row.id}
                              defaultValue={row.title}
                              placeholder="Task"
                              id={"title_" + row.id}
                            ></input>
                          ) : (
                            row.name
                          )}{" "}
                        </td>
                        <td>
                          <input
                            key={row.id}
                            name="hr"
                            className="hr"
                            placeholder="Hrs"
                            id={"time_" + row.id}
                            type="text"
                            onKeyUp={setHr}
                            defaultValue={
                              row.time !== undefined ? row.time : ""
                            }
                            max="9"
                          />
                        </td>
                        <td>
                          <input
                            name="cardInfo"
                            className="cardInfo"
                            id={"cardInfo_" + row.id}
                            defaultValue={row.id + "_" + row.name}
                            type="hidden"
                          />
                          <input
                            name="clientId"
                            className="clientId"
                            id={"client_" + row.id}
                            defaultValue={trelloInfo.clientId.split("_")[0]}
                            type="hidden"
                          />
                          <input
                            name="boardId"
                            className="boardId"
                            id={"boardId_" + row.id}
                            defaultValue={
                              trelloInfo.boardId
                                ? trelloInfo.boardId.split("_")[2]
                                : ""
                            }
                            type="hidden"
                          />
                          <input
                            name="listId"
                            className="listId"
                            id={"listId_" + row.id}
                            defaultValue={row.idList}
                            type="hidden"
                          />
                          <input
                            name="listName"
                            className="listName"
                            id={"listName_" + row.id}
                            defaultValue={getListName(row.idList)}
                            type="hidden"
                          />
                          <input
                            name="taskId_"
                            className="taskId_"
                            id={"taskId_" + row.id}
                            defaultValue={row.taskId}
                            type="hidden"
                          />
                          <input
                            name="commentId_"
                            className="commentId_"
                            id={"commentId_" + row.id}
                            defaultValue={row.comment_id}
                            type="hidden"
                          />
                          <textarea
                            rows="2"
                            autoComplete="off"
                            name={"descp" + row.id}
                            type="textbox"
                            key={row.id}
                            // onChange={event=>setDescription(event,row.id)}
                            placeholder="Enter your description"
                            defaultValue={row.description}
                            className="task-descp"
                            id={"descp_" + row.id}
                            //className="task-descp"
                          ></textarea>
                        </td>
                        {displayCard.length > 0 &&
                        displayCard.length !== "" &&
                        displayCard.length !== null &&
                        displayCard[0].id != 1 ? (
                          <td className="trello-labels">
                            {/* <select
                              id={"label_" + row.id}
                              className="form-control"
                            >
                              {row.label_list.length > 0 &&
                              row.label_list.length !== "" &&
                              row.label_list.length !== null
                                ? row.label_list.map((data, indx) => (
                                    <option
                                      style={{ background: data.color }}
                                      value={
                                        data.id +
                                        "_" +
                                        data.name +
                                        "_" +
                                        data.idBoard
                                      }
                                    >
                                      {data.name}
                                    </option>
                                  ))
                                : "<option value='others'>Others</option>"}
                            </select> */}
                            <input
                              type="hidden"
                              id={"oldlabels_" + row.id}
                              defaultValue={
                                row.labels.length > 0 &&
                                row.labels.length !== "" &&
                                row.labels.length !== null
                                  ? JSON.stringify(row.labels)
                                  : ""
                              }
                            />
                            <input
                              type="hidden"
                              id={"newlabels_" + row.id}
                              defaultValue={
                                row.labels.length > 0 &&
                                row.labels.length !== "" &&
                                row.labels.length !== null
                                  ? JSON.stringify(row.labels)
                                  : ""
                              }
                            />

                            <Select
                              //onSelect={() => test23(row.id)}
                              key={row.id}
                              id={row.id}
                              defaultValue={
                                row.labels.length > 0 &&
                                row.labels.length !== "" &&
                                row.labels.length !== null
                                  ? row.labels
                                  : ""
                              }
                              placeholder="Select label"
                              onChange={(event) => setNewlabels(row.id, event)}
                              className="form-control trello-labels-multi"
                              options={labelListOptions}
                              isMulti
                            />
                          </td>
                        ) : (
                          ""
                        )}
                        <td className="task-icons">
                          {/* {row.name == "others" ? (
                              <button onClick={() => addRow()}>add</button>
                            ) : (
                              ""
                            )}
                            {row.name == "others" ? (
                              <button onClick={() => removeRow(index)}>
                                remove
                              </button>
                            ) : (
                              ""
                            )} */}
                          {row.taskId !== "" && row.taskId !== null ? (
                            <button
                              type="button"
                              onClick={() => updateComment(row.id)}
                              value="Update"
                              className="updateTask"
                              title="Update"
                            >
                              <VscSaveAs />
                            </button>
                          ) : (
                            <button
                              type="button"
                              id={row.id}
                              onClick={() => saveComment(row.id)}
                              value="Save"
                              className="saveTask"
                              title="Save"
                            >
                              <IoMdCheckmarkCircleOutline />
                            </button>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <td
                      colspan="4"
                      className="norecords-display task-mgmt-norecords"
                    >
                      No Records To Display
                    </td>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProdTaskManagement;
