import React, { useState, useEffect, useContext } from "react";
import Announcement from "./Announcement";
import { ImSwitch } from "react-icons/im";
import { Col, Container, Row, Modal } from "react-bootstrap";
//import { useLocation } from "react-router-dom";
import axios from "axios";
import { LoginManageContext } from "./../App";
import Privacy from '../Images/access-denied.png';
const Header = (props) => {
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  //const navigate = useNavigate();
  const [time, setTime] = useState();
  const [changePassword, setChangePassword] = useState({});
  // const userContext = useContext(LoginManageContext);
  // const uId = localStorage.getItem("userId");
  // const stateInfo = userContext.userInfo;
  const uId = localStorage.getItem("userId");
  const { loginData } = useContext(LoginManageContext);
  var stateInfo = loginData;
  //console.log('personalInfo',personalInfo);
  /*
   * logout the user from session
   */
  const logout = (e) => {
    localStorage.setItem("userId", 0);
    localStorage.setItem("userinfo", 0);
    localStorage.setItem("task-added-status", "false");
    window.location.href = "/";
  };

  //const location = useLocation();

  const upTime = () => {
    let d = new Date();
    let wday = d.toLocaleString("en-us", { weekday: "short" });
    // let hrs = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
    let hrs = d.getHours() % 12;
    hrs = hrs < 10 ? "0" + hrs : hrs;
    let min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
    //let sec = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
    // let fulltime = wday + ", " + hrs + ":" + min + " PM";
    let am_pm = d.getHours() >= 12 ? "PM" : "AM";
    // let am_pm = hrs >= 12 ? "PM" : "AM";
    let fulltime = `${wday}, ${hrs}:${min} ${am_pm}`;

    // alert(fulltime);
    return fulltime;
  };

  useEffect(() => {
    setInterval(() => {
      setTime(upTime());
    }, 1000);
  }, []);

  const modalOpen = () => {
    setChangePassword({ ...changePassword, success: "undefined" });
    setShow(true);
  };

  const modalClose = () => {
    setShow(false);
  };

  //set value for change password
  const setVal = (e) => {
    setChangePassword({
      ...changePassword,
      [e.target.name]: e.target.value,
      success: "undefined",
      uId: uId,
    });
  };

  /*
   * change password
   */
  const resetPassword = (e) => {
    e.preventDefault();
    document.getElementById("change-pwd").disabled = true;
    setLoader(true);
    if (changePassword.password === changePassword.cnfpassword) {
      // Make a request for a user with a given ID
      axios
        .post("/changePasswordApi.php", changePassword)
        .then(function (response) {
          const res = response.data;
          if (res.statusCode === 200) {
            setChangePassword({ ...changePassword, success: "true" });
            document.getElementById("change-pwd").disabled = false;
            setLoader(false);
            //setShow(false);
          } else {
            console.log("Oops something went wrong");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setChangePassword({ ...changePassword, success: "false" });
      document.getElementById("change-pwd").disabled = false;
      setLoader(false);
    }
  };

  const hamburgerMenu = (e) => {
    let menu = document.getElementById("hamburger");
    let overlay = document.getElementById("overlay");
    let sidebar = document.getElementById("sidebar");
    let close = document.getElementById("close-sidebar");

    menu.addEventListener("click", function () {
      sidebar.style.left = "0%";
      overlay.style.display = "block";
      // console.log(e);
    });

    overlay.addEventListener("click", function (event) {
      sidebar.style.left = "-100%";
      overlay.style.display = "none";
    });

    close.addEventListener("click", function (event) {
      sidebar.style.left = "-100%";
      overlay.style.display = "none";
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={modalClose}
        dialogClassName="change-pswd"
        aria-labelledby="Add Trello Task"
        backdrop="static"
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">
              <div className="modal_title_wrapper">
                <img src={Privacy}/>
                <div className="quote_sec">
                  <h3>Change Password</h3>
                  <p>Treat your password like your toothbrush. Don't let anybody else use it, and get a new one every six months.</p>
                </div>
              </div>
          </Modal.Title>
        </Modal.Header>
        <hr className="hr_line"/>
        {loader === true ? (
          <div className="loader">
            <img src="/img/hrm-loader.svg" alt="loader" />
          </div>
        ) : (
          ""
        )}
        <form method="post" onSubmit={resetPassword}>
          <Modal.Body>
            <Container>
              {changePassword.success === "false" ? (
                <span className="pwd-error d-block text-danger mb-3">
                  Passwords do not match.
                </span>
              ) : (
                ""
              )}
              {changePassword.success === "true" ? (
                <span className="pwd-success d-block text-success mb-3">
                  Password updated successfully.
                </span>
              ) : (
                ""
              )}

              <Row>
                <Col md={12}>
                  <label htmlFor="new-pwd"> New Password </label>
                  <input
                    type="password"
                    name="password"
                    required
                    onChange={setVal}
                    className="form-control"
                  />
                </Col>
                <Col md={12} className="mt-3">
                  <label htmlFor="cnew-pwd">
                    Confirm Password
                  </label>

                  <input
                    required
                    type="password"
                    name="cnfpassword"
                    onChange={setVal}
                    className="form-control"
                  />
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="nbl-btn" id="change-pwd">
              Update
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <div className="header">
        <div id="hamburger">
          <span className="toggle-menu" onClick={hamburgerMenu}></span>
        </div>
        <h4> {time} </h4>
        <Announcement />
        <div className="login">
          <span>
            <span className="change-pwd" onClick={modalOpen}>
              Change Password?
            </span>
            <img
              className="empImg"
              // src={
              //   stateInfo.userData.image !== "" &&
              //   stateInfo.userData.image !== null
              //     ? "https://crm.nablasol.net/fromAPI/" +
              //       stateInfo.userData.image
              //     : stateInfo.userData.gender === "Male"
              //     ? "https://crm.nablasol.net/fromAPI/male.png"
              //     : "https://crm.nablasol.net/fromAPI/female.png"
              // }
              src={
                stateInfo.userData.image !== "" &&
                stateInfo.userData.image !== null
                  ? "https://crm.nablasol.net/fromAPI/" + stateInfo.userData.image: stateInfo.userData.photo_url
              }
              alt="emp-img"
            />
          </span>
          <span>
            <span className="logout-text"> Logout </span>
            <button onClick={logout} title="Logout">
              <ImSwitch />
            </button>
          </span>
        </div>
      </div>
      <div id="overlay"></div>
    </>
  );
};
const addZero = (i) => {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
};

const getDateFormate = (data, birthdayFlag, dayFlag, onlyDate, onlyTime) => {
  let d = new Date(data);
  let wday = d.toLocaleString("en-us", { weekday: "short" });
  let month = d.toLocaleString("default", { month: "short" });
  let date = d.getDate();
  let fulltime = "";
  if (birthdayFlag === true) {
    fulltime = `${date} ${month}`;
  } else {
    fulltime = `${wday}, ${month} ${date}`;
  }

  if (dayFlag === true) {
    let wday = d.toLocaleString("en-us", { weekday: "long" });
    fulltime = `${wday}`;
  }
  if (onlyDate === true) {
    let month = d.getMonth() + 1;
    let year = d.getFullYear();
    fulltime = `${date}-${month}-${year}`;
  }
  if (onlyTime === true) {
    let hours = addZero(d.getHours());
    let mins = addZero(d.getMinutes());
    let sec = addZero(d.getSeconds());
    //let am_pm = hours >= 12 ? "PM" : "AM";
    var time = String(`${hours}:${mins}:${sec}`);

    //const tConvert=(time)=> {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    fulltime = time.join(""); // return adjusted time or original string
    // }
  }
  // alert(fulltime);
  return fulltime;
};
// setInterval(Header(), 1000);

export default Header;
export { getDateFormate };
