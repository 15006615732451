// import SideMenu from "./SideMenu";
import React, { useContext } from "react";
import { MdPhoneInTalk } from "react-icons/md";
import { GrMail } from "react-icons/gr";
import { MdDashboard } from "react-icons/md";
import { RiContactsBookFill, RiCloseFill } from "react-icons/ri";
import { MdTimer, MdPolicy } from "react-icons/md";
import { FaUmbrellaBeach } from "react-icons/fa";
import { VscPreview } from "react-icons/vsc";
import { GoSignOut } from "react-icons/go";
import { RiTeamFill } from "react-icons/ri";
import { TbReportSearch } from 'react-icons/tb';
import { GrTask } from "react-icons/gr";
import { Link, NavLink } from "react-router-dom";
import { LoginManageContext } from "./../App";


const Sidebar = (props) => {
  // const userContext = useContext(LoginManageContext);

  // var stateInfo = userContext.userInfo;
  //const uId = localStorage.getItem("userId");
  const { loginData } = useContext(LoginManageContext);
  const userRole = loginData.userData.user_role_c;
  var stateInfo = loginData;
  return (
    <div id="sidebar">
      <div className="nbl-logo">
        <span id="close-sidebar">{<RiCloseFill />}</span>
        <img
          src="https://www.nablasol.com/wp-content/uploads/2020/09/nablasolblue-logo.png"
          alt="NBL logo"
          className="img-fluid"
        />
      </div>
      <h5> HRM </h5>
      <div id="emp-sect">
        <img
          className="emp-photo"
          // src={
          //   stateInfo.userData.image !== "" && stateInfo.userData.image !== null
          //     ? "https://crm.nablasol.net/fromAPI/" + stateInfo.userData.image
          //     : stateInfo.userData.gender === "Male"
          //     ? "https://crm.nablasol.net/fromAPI/male.png"
          //     : "https://crm.nablasol.net/fromAPI/female.png"
          // }
          src={
            stateInfo.userData.image !== "" && stateInfo.userData.image !== null
              ? "https://crm.nablasol.net/fromAPI/" + stateInfo.userData.image
              : stateInfo.userData.photo_url
          }
          // src=""
          alt={
            stateInfo.userData.first_name + " " + stateInfo.userData.last_name
          }
        />
        <h5 className="text-center emp-name">
          {stateInfo.userData.first_name} {stateInfo.userData.last_name}
        </h5>
        <span className="text-center d-block emp-desig">
          {stateInfo.userData.designation}
        </span>
        <ul className="list-inline text-center mb-0">
          <li className="list-inline-item me-4 mt-2">
            <a
              href={"tel:" + stateInfo.userData.telephone}
              title={stateInfo.userData.telephone}
            >
              <MdPhoneInTalk />
            </a>
          </li>
          <li className="list-inline-item">
            <a
              href={"mailto:" + stateInfo.userData.companyemail}
              title={stateInfo.userData.companyemail}
            >
              <GrMail />
            </a>
          </li>
        </ul>
      </div>
      <nav className="menu-list-wrapper">
        <ul className="menu-list">
          <li className="listItem">
            <NavLink
              to="/dashboard"
              state={{ userInfo: stateInfo.userData }}
              activeClassName="hello"
            >
              <span className="labelicon">
                <MdDashboard />
              </span>
              Dashboard
            </NavLink>
          </li>
          <li className="listItem">
            <NavLink
              to="/manage-tasks"
              state={{ userInfo: stateInfo.userData }}
              activeClassName="hello"
            >
              <span className="labelicon">
                <GrTask />
              </span>
              Manage Tasks
            </NavLink>
          </li>
          {/* {loginData?.userData?.companyemail ===
            "sourabh.patial@nablasol.com" ||
          loginData?.userData?.companyemail ===
            "dhirendra.kumar@nablasol.com" ||
          loginData?.userData?.companyemail === "gurpreet.modi@nablasol.com" ||
          loginData?.userData?.companyemail ===
            "manjeet.sharma@nablasol.com" ? (
            <li className="listItem">
              <NavLink
                to="/manage-prod-tasks"
                state={{ userInfo: stateInfo.userData }}
                activeClassName="hello"
              >
                <span className="labelicon">
                  <GrTask />
                </span>
                Manage Prod Tasks
              </NavLink>
            </li>
          ) : null} */}
          <li className="listItem">
            <NavLink
              to="/team-members"
              state={{ userInfo: stateInfo.userData }}
              activeClassName="hello"
            >
              <span className="labelicon">
                <RiTeamFill />
              </span>
              Team Members
            </NavLink>
          </li>
          <li className="listItem">
            {/* <Link to="/employee-info" state={{ testvalue: "hello" }}>Create Idea</Link> */}
            <NavLink
              to="/employee-info"
              state={{ userInfo: stateInfo.userData }}
              activeClassName="hello"
            >
              <span className="labelicon">
                <RiContactsBookFill />
              </span>
              Personal Details
            </NavLink>
          </li>
          <li className="listItem">
            <NavLink to="/records" state={{ userInfo: stateInfo.userData }}>
              <span className="labelicon">
                <MdTimer />
              </span>
              My Records
            </NavLink>
          </li>
          <li className="listItem">
            <NavLink to="/holiday" state={{ userInfo: stateInfo.userData }} end>
              <span className="labelicon">
                <FaUmbrellaBeach />
              </span>
              Holidays
            </NavLink>
          </li>
          <li className="listItem">
            <NavLink
              to="/quarterly-self-review"
              state={{ userInfo: stateInfo.userData }}
              end
            >
              <span className="labelicon">
                <VscPreview />
              </span>
              Quarterly Self Review
            </NavLink>
          </li>
          <li className="listItem">
            <NavLink
              to="/leave-policy"
              state={{ userInfo: stateInfo.userData }}
              end
            >
              <span className="labelicon">
                <GoSignOut />
              </span>
              Leave
            </NavLink>
          </li>
          <li className="listItem">
            <NavLink to="/terms" state={{ userInfo: stateInfo.userData }} end>
              <span className="labelicon">
                <MdPolicy />
              </span>
              Terms & Policies
            </NavLink>
          </li>
          {userRole === "TL" ||  userRole === "CEO" || userRole === "HR" || userRole === "PM" ? (
          <li className="listItem">
            <NavLink to="/reports" state={{ userInfo: stateInfo.userData }} end>
              <span className="labelicon">
                <TbReportSearch />
              </span>
              Reports
            </NavLink>
          </li>
          ) : null}
        </ul>
      </nav>
      <span className="copyright">
        <a href="https://www.nablasol.com/">
          &copy; Nablasol Digital Services Pvt. Ltd.
        </a>
      </span>
    </div>
  );
};

export default Sidebar;
