import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { NavLink } from "react-router-dom"; 
import { TiBook,TiThList } from "react-icons/ti";
import { BsFileSpreadsheetFill } from "react-icons/bs";
import { HiUsers } from "react-icons/hi";
import { ImUserTie } from "react-icons/im";

function Reports(props) {
  return (
    <>
      {" "}
      <div className="main">
        <Sidebar />
        <div id="primary">
          <Header />
          <div id="rpt" className="main-view">
            <div className="view-head">
              <h6> Reports </h6>
            </div>
            <div className="view-grid">
              <div className="qsr-erp grid-dashlet" id="wall-fame">
                <div className="qtr-wrapper">
                  <NavLink to={`/work-report-aggregate`} className="report-title">
                      <span className="labelicon">
                        <TiBook /> 
                      </span>                                
                      <a>                                
                          Employee Work Report Aggregate
                      </a>    
                  </NavLink> 
                </div>
              </div>

              <div className="qsr-erp grid-dashlet" id="wall-fame">
                <div className="qtr-wrapper">
                  <NavLink to={`/work-report-details`} className="report-title">
                      <span className="labelicon">
                        <TiThList />
                      </span>  
                      <a>
                      Employee Work Report Details
                      </a>    
                  </NavLink> 
                </div>
              </div>

              <div className="qsr-erp grid-dashlet" id="wall-fame">
                <div className="qtr-wrapper">   
                  <NavLink to={`/client-timesheet`} className="report-title">
                      <span className="labelicon">
                        <BsFileSpreadsheetFill />
                      </span>
                      <a>
                        Client Timesheet
                      </a>    
                  </NavLink>                        
                </div>
              </div> 

              <div className="qsr-erp grid-dashlet" id="wall-fame">
                <div className="qtr-wrapper">
                  <NavLink to={`/trello-tasks-employee`} className="report-title">
                      <span className="labelicon">
                        <HiUsers />
                      </span>
                      <a>
                        Trello Assigned Tasks By Employee
                      </a>    
                  </NavLink>
                  </div>
              </div> 

              <div className="qsr-erp grid-dashlet" id="wall-fame">
                <div className="qtr-wrapper"> 
                  <NavLink to={`/trello-tasks-clients`} className="report-title">
                      <span className="labelicon">
                        <ImUserTie />
                      </span>
                      <a>
                        Trello Assigned Tasks By Clients
                      </a>    
                  </NavLink>                        
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Reports;
