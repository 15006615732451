import React,{ useState, useEffect }  from "react";
import axios from "axios";

const Announcement = () => {
  const [showNotification, setShowNotification] = useState({});

  /*
  * to display notification
  */
  const displayNotification=()=>{
    axios
    .get("/getNotificationApi.php")
    .then(function (response) {
      const res = response.data;
      console.log(res);
      if (res.statusCode === 200) {
    
        setShowNotification(res.result);
      } else {
        console.log("Oops something went wrong");
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  useEffect(() => {
    displayNotification();
  }, []);
  return (
    <>
    <div id="announcement" style={{display:  showNotification.length > 0 && showNotification.length !== ""  && showNotification.length !== null? 'block' : 'none'} } >
      {showNotification.length > 0 &&
      showNotification.length !== "" &&
      showNotification.length !== null
      ? showNotification.map((row, index) => (
      <h5> {row.name}  {row.description}</h5>
      ))
      : ""}
    </div>
    </>
  );
};

export default Announcement;
