import React, { useContext, useEffect, useState, useMemo } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { BsBackspaceFill } from 'react-icons/bs';

const AllClients = () => {
    
    const [Clients, setClients] = useState({});
    
    const getAllClientInfo = async () => {
        const res = await axios.post("/getAllClientsApi.php")
        const response = await res.data.result;
        setClients(response);         
    };
    
    useEffect(() => {
      getAllClientInfo();
    }, []);

    return (
      <>
        <div className="main">
          <Sidebar />
          <div id="primary">
            <Header />
            <div id="teams" className="main-view">
              <div className="view-head">
                <h6> Clients </h6>
                <NavLink
                  to="/reports" 
                  activeClassName="hello"
              >
                  {" "}
                  <span className="view_back_btn">Back <BsBackspaceFill /></span>
                </NavLink> 
              </div>
              <div class="team-wrapper">
                <div class="team-grid">
                  <table>
                    <thead>
                      <tr>
                        <th className="name">Name </th>                      
                      </tr>
                    </thead>
                    <tbody>
                      {Clients.length > 0
                        ? Clients.map((row, index) => (
                          <tr>
                            <td>
                              <span className="team-mate">                               
                                <div className="emp-details">                                
                                  <NavLink 
                                    to={`/trello-tasks-client/${row.id}`}
                                    state={{clientInfo: row.name}}
                                    activeClassName="hello"
                                  >                                 
                                    <a className="team-emp-name">
                                      {row.name}{" "}
                                    </a>
                                  </NavLink>                                
                                </div>
                              </span>
                            </td>                          
                          </tr>
                        ))
                        : ""}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default AllClients;
  