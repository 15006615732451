import React, { useState, useEffect, useContext } from "react";
import validator from "validator";
import Alert from "./Alert";
import { MdFileUpload } from "react-icons/md";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Select from "react-select";
import "react-multiple-select-dropdown-lite/dist/index.css";
//import { useLocation} from "react-router-dom";
import { LoginManageContext } from "./../App";
import axios from "axios";
//import Sidebar from  "./Sidebar";
function EmpInfo(props) {
  return (
    <div className="emp-info-cc grid-dashlet">
      <div className="emp-left">
        <div className="emp-img">
          <img
            src={
              props.userInfo.image !== "" && props.userInfo.image !== null
                ? "https://crm.nablasol.net/fromAPI/" + props.userInfo.image
                : props.userInfo.gender === "Male"
                ? "https://crm.nablasol.net/fromAPI/male.png"
                : "https://crm.nablasol.net/fromAPI/female.png"
            }
            alt=""
          />
        </div>
        <div className="emp-details">
          <h5> {props.userInfo.first_name + " " + props.userInfo.last_name}</h5>
          <div className="emp-role">
            <span className="emp-desg">{props.userInfo.designation}</span>
            <span className="emp-team">{props.userInfo.team_name} </span>
          </div>
        </div>
      </div>
      <div className="emp-right">
        <div className="row">
          <div className="col-md-4">
            <span className="emp-rt-heads"> D.O.J : </span>
            <span>{props.userInfo.u_doj} </span>
          </div>
          <div className="col-md-8">
            <span className="emp-rt-heads"> Employee ID : </span>
            <span> {props.userInfo.greythr_user_id_c} </span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <span className="emp-rt-heads"> Phone : </span>
            <span> {props.userInfo.telephone} </span>
          </div>
          <div className="col-md-8">
            <span className="emp-rt-heads"> Office Email : </span>
            <span> {props.userInfo.companyemail}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

function EmpPersonalInfo(props) {
  var profi = new Array();
  var userProficientSkills = "";
  if (
    props.userInfo.tech_skills_emp_c != null &&
    props.userInfo.tech_skills_emp_c != ""
  ) {
    userProficientSkills = props.convertInAssocArray(
      props.userInfo.tech_skills_emp_c
        .replace(/[^a-zA-Z0-9]/g, " ")
        .trim()
        .replace(/\s+/g, ",")
        .split(",")
    );
  }
  var userAvgSkills = "";
  if (
    props.userInfo.tech_avg_skills_c != null &&
    props.userInfo.tech_avg_skills_c != ""
  ) {
    userAvgSkills = props.convertInAssocArray(
      props.userInfo.tech_avg_skills_c
        .replace(/[^a-zA-Z0-9]/g, " ")
        .trim()
        .replace(/\s+/g, ",")
        .split(",")
    );
  }
  var userBeginnerSkills = "";
  if (
    props.userInfo.tech_beginner_skills_c != null &&
    props.userInfo.tech_beginner_skills_c != ""
  ) {
    userBeginnerSkills = props.convertInAssocArray(
      props.userInfo.tech_beginner_skills_c
        .replace(/[^a-zA-Z0-9]/g, " ")
        .trim()
        .replace(/\s+/g, ",")
        .split(",")
    );
  }
  const proficientSkills = [
    { label: ".Net", value: "DotNet" },
    { label: "AJax", value: "AJax" },
    { label: "Analytics Reporting", value: "AnalyticsReporting" },
    { label: "AWS", value: "AWS" },
    { label: "BackBone Js", value: "BackBoneJs" },
    { label: "BootStrap", value: "BootStrap" },
    { label: "C#", value: "CSharp" },
    { label: "CodeIgniter", value: "CodeIgniter" },
    { label: "CSS", value: "CSS" },
    { label: "Docusign", value: "Docusign" },
    { label: "Email Marketing", value: "EmailMarketing" },
    { label: "HTML", value: "HTML" },
    { label: "Java", value: "Java" },
    { label: "JavaScript", value: "JavaScript" },
    { label: "JQuery", value: "JQuery" },
    { label: "Laravel", value: "Laravel" },
    { label: "Mongo DB", value: "MongoDB" },
    { label: "MS Office", value: "MSOffice" },
    { label: "MySql", value: "MySql" },
    { label: "Node Js", value: "NodeJs" },
    { label: "Photoshop", value: "Photoshop" },
    { label: "PHP", value: "PHP" },
    { label: "Python", value: "Python" },
    { label: "React Js", value: "ReactJs" },
    { label: "Angular", value: "Angular" },
    { label: "Angular Js", value: "AngularJs" },
    { label:"Salesforce CRM", value:"SalesforceCRM"},
    { label: "Sql", value: "Sql" },
    { label: "Strapi", value: "Strapi" },
    { label: "SugarCRM", value: "SugarCRM" },
    { label: "SuiteCRM", value: "SuiteCRM" },
    { label: "Twilio", value: "Twilio" },
    { label: "WordPress", value: "WordPress" },
    { label: "Tableau", value: "Tableau" },
    { label: "Jira", value: "Jira" },
    { label: "Kotlin", value: "Kotlin" },
    { label: "PowerBI", value: "PowerBI" },
    { label: "Redash", value: "Redash" },
    { label: "QC-ALM", value: "QC-ALM" },
    { label: "MERN", value: "MERN" },
    { label: "PowerAutomate", value: "PowerAutomate" },
    { label: "Recruitment", value: "Recruitment" },
    { label: "Onboarding", value: "Onboarding" },
    { label: "Client Handling", value: "ClientHandling" },
    { label: "Content Writing", value: "ContentWriting" },
    { label: "Agile Project Management", value: "AgileProjectManagement" },
    { label: "SCRUM", value: "SCRUM" },
    { label: "Swim lanes and Kanban board", value: "SwimLanesAndKanbanBoard" },
    { label: "Project Planning Budgeting and scheduling", value: "ProjectPlanningBudgetingAndScheduling" },
    { label: "Conflict management", value: "ConflictManagement" },
    { label: "Sidecar", value: "Sidecar" },
    { label: "Heroku", value: "Heroku" },
    { label: "Communication And Interpersonal Skills", value: "CommunicationAndInterpersonalSkills" },
    { label: "Documentation And Decision Making", value: "DocumentationAndDecisionMaking" },
    { label: "People Management", value: "PeopleManagement" },
    
    {
      label: "Employee Grievance Handling",
      value: "EmployeeGrievanceHandling",
    },
  ];

  const avgSkills = [
    { label: ".Net", value: "DotNet" },
    { label: "AJax", value: "AJax" },
    { label: "Analytics Reporting", value: "AnalyticsReporting" },
    { label: "AWS", value: "AWS" },
    { label: "BackBone Js", value: "BackBoneJs" },
    { label: "BootStrap", value: "BootStrap" },
    { label: "C#", value: "CSharp" },
    { label: "CodeIgniter", value: "CodeIgniter" },
    { label: "CSS", value: "CSS" },
    { label: "Docusign", value: "Docusign" },
    { label: "EmailMarketing", value: "Email Marketing" },
    { label: "HTML", value: "HTML" },
    { label: "Java", value: "Java" },
    { label: "JavaScript", value: "JavaScript" },
    { label: "JQuery", value: "JQuery" },
    { label: "Laravel", value: "Laravel" },
    { label: "Mongo DB", value: "MongoDB" },
    { label: "MS Office", value: "MSOffice" },
    { label: "MySql", value: "MySql" },
    { label: "Node Js", value: "NodeJs" },
    { label: "Photoshop", value: "Photoshop" },
    { label: "PHP", value: "PHP" },
    { label: "Python", value: "Python" },
    { label: "React Js", value: "ReactJs" },
    { label: "Angular", value: "Angular" },
    { label: "Angular Js", value: "AngularJs" },
    { label:"Salesforce CRM", value:"SalesforceCRM"},
    { label: "Sql", value: "Sql" },
    { label: "Strapi", value: "Strapi" },
    { label: "SugarCRM", value: "SugarCRM" },
    { label: "SuiteCRM", value: "SuiteCRM" },
    { label: "Twilio", value: "Twilio" },
    { label: "WordPress", value: "WordPress" },
    { label: "Tableau", value: "Tableau" },
    { label: "Jira", value: "Jira" },
    { label: "Kotlin", value: "Kotlin" },
    { label: "PowerBI", value: "PowerBI" },
    { label: "Redash", value: "Redash" },
    { label: "QC-ALM", value: "QC-ALM" },
    { label: "MERN", value: "MERN" },
    { label: "PowerAutomate", value: "PowerAutomate" },
    { label: "Recruitment", value: "Recruitment" },
    { label: "Onboarding", value: "Onboarding" },
    { label: "Client Handling", value: "ClientHandling" },
    { label: "Content Writing", value: "ContentWriting" },
    { label: "Agile Project Management", value: "AgileProjectManagement" },
    { label: "SCRUM", value: "SCRUM" },
    { label: "Swim lanes and Kanban board", value: "SwimLanesAndKanbanBoard" },
    { label: "Project Planning Budgeting and scheduling", value: "ProjectPlanningBudgetingAndScheduling" },
    { label: "Conflict management", value: "ConflictManagement" },
    { label: "Sidecar", value: "Sidecar" },
    { label: "Heroku", value: "Heroku" },
    { label: "Communication And Interpersonal Skills", value: "CommunicationAndInterpersonalSkills" },
    { label: "Documentation And Decision Making", value: "DocumentationAndDecisionMaking" },
    { label: "People Management", value: "PeopleManagement" },
    {
      label: "Employee Grievance Handling",
      value: "EmployeeGrievanceHandling",
    },
  ];

  const beginnerSkills = [
    { label: ".Net", value: "DotNet" },
    { label: "AJax", value: "AJax" },
    { label: "Analytics Reporting", value: "AnalyticsReporting" },
    { label: "AWS", value: "AWS" },
    { label: "BackBone Js", value: "BackBoneJs" },
    { label: "BootStrap", value: "BootStrap" },
    { label: "C#", value: "CSharp" },
    { label: "CodeIgniter", value: "CodeIgniter" },
    { label: "CSS", value: "CSS" },
    { label: "Docusign", value: "Docusign" },
    { label: "EmailMarketing", value: "Email Marketing" },
    { label: "HTML", value: "HTML" },
    { label: "Java", value: "Java" },
    { label: "JavaScript", value: "JavaScript" },
    { label: "JQuery", value: "JQuery" },
    { label: "Laravel", value: "Laravel" },
    { label: "Mongo DB", value: "MongoDB" },
    { label: "MS Office", value: "MSOffice" },
    { label: "MySql", value: "MySql" },
    { label: "Node Js", value: "NodeJs" },
    { label: "Photoshop", value: "Photoshop" },
    { label: "PHP", value: "PHP" },
    { label: "Python", value: "Python" },
    { label: "React Js", value: "ReactJs" },
    { label: "Angular", value: "Angular" },
    { label: "Angular Js", value: "AngularJs" },
    { label:"Salesforce CRM", value:"SalesforceCRM"},
    { label: "Sql", value: "Sql" },
    { label: "Strapi", value: "Strapi" },
    { label: "SugarCRM", value: "SugarCRM" },
    { label: "SuiteCRM", value: "SuiteCRM" },
    { label: "Twilio", value: "Twilio" },
    { label: "WordPress", value: "WordPress" },
    { label: "Tableau", value: "Tableau" },
    { label: "Jira", value: "Jira" },
    { label: "Kotlin", value: "Kotlin" },
    { label: "PowerBI", value: "PowerBI" },
    { label: "Redash", value: "Redash" },
    { label: "QC-ALM", value: "QC-ALM" },
    { label: "MERN", value: "MERN" },
    { label: "PowerAutomate", value: "PowerAutomate" },
    { label: "Recruitment", value: "Recruitment" },
    { label: "Onboarding", value: "Onboarding" },
    { label: "Client Handling", value: "ClientHandling" },
    { label: "Content Writing", value: "ContentWriting" },
    { label: "Agile Project Management", value: "AgileProjectManagement" },
    { label: "SCRUM", value: "SCRUM" },
    { label: "Swim lanes and Kanban board", value: "SwimLanesAndKanbanBoard" },
    { label: "Project Planning Budgeting and scheduling", value: "ProjectPlanningBudgetingAndScheduling" },
    { label: "Conflict management", value: "ConflictManagement" },
    { label: "Sidecar", value: "Sidecar" },
    { label: "Heroku", value: "Heroku" },
    { label: "Communication And Interpersonal Skills", value: "CommunicationAndInterpersonalSkills" },
    { label: "Documentation And Decision Making", value: "DocumentationAndDecisionMaking" },
    { label: "People Management", value: "PeopleManagement" },
    {
      label: "Employee Grievance Handling",
      value: "EmployeeGrievanceHandling",
    },
  ];
  return (
    <div className="emp-personal-info grid-dashlet">
      <form action="#" method="POST" onSubmit={props.updatePerInfo}>
        <div className="emp-info-head">
          <span className="emp-info-head-title">Personal Information</span>
          <button className="emp-info-head-edit" title="Update">
            <MdFileUpload /> <span className="update-txt"> Update </span>
          </button>
        </div>
        {/* {props.alertValue?.alertPerText === "on" ? (
          <Alert alertText={props?.alertValue} />
        ) : (
          ""
        )} */}
        <div className="emp-info-body">
          <div className="row">
            <div className="col-md-6">
              <label>Full Name</label>
              <input
                type="text"
                name="full_name"
                onChange={props.setValue}
                defaultValue={
                  props.userInfo.first_name + " " + props.userInfo.last_name
                }
                required
                className="form-control"
              />
            </div>
            <div className="col-md-6">
              <label>Phone</label>
              <input
                type="text"
                name="telephone"
                onChange={props.setValue}
                defaultValue={props.userInfo.telephone}
                required
                className="form-control"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>Personal Email</label>
              <input
                type="email"
                name="email"
                onChange={props.setValue}
                defaultValue={props.userInfo.email}
                required
                className="form-control"
              />
            </div>
            <div className="col-md-6">
              <label> PAN Number </label>
              <input
                type="text"
                name="pan_no"
                onChange={props.setValue}
                defaultValue={props.userInfo.pan_no}
                required
                className="form-control"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>Date Of Birth </label>
              <input
                type="date"
                name="date_of_birth"
                onChange={props.setValue}
                defaultValue={props.userInfo.u_dob}
                required
                className="form-control"
              />
            </div>
            <div className="col-md-6">
              <label> Update Image </label>
              <input
                type="file"
                name="image"
                onChange={props.setValue}
                className="form-control"
                // defaultValue={props.userInfo.image}
              />
            </div>
          </div>
          <div>
            <fieldset>
              <legend> Skill Set</legend>
              <div>
                <label> Proficient Skills </label>
                <Select
                  isMulti
                  required
                  name="tech_skills_emp"
                  onChange={props.setProficientSkills}
                  placeholder="Select Proficient Skills"
                  options={proficientSkills}
                  defaultValue={
                    userProficientSkills.length > 0 ? userProficientSkills : ""
                  }
                />
              </div>
              <div>
                <label> Average Skills </label>
                <Select
                  required
                  name="tech_avg_skills"
                  onChange={props.setAverageSkills}
                  isMulti
                  placeholder="Select Average Skills"
                  options={avgSkills}
                  defaultValue={userAvgSkills.length > 0 ? userAvgSkills : ""}
                />
              </div>
              <div>
                <label> Beginner Skills </label>
                <Select
                  required
                  isMulti
                  name="tech_beginner_skills"
                  onChange={props.setBeginnerSkills}
                  placeholder="Select Beginner Skills "
                  options={beginnerSkills}
                  defaultValue={
                    userBeginnerSkills.length > 0 ? userBeginnerSkills : ""
                  }
                />
              </div>
            </fieldset>
            <fieldset>
              <legend> Permanent Address </legend>
              <div className="permanent-add row">
                <div className="col-md-8">
                  {/* <label> Street </label> */}
                  <label> Street </label>
                  <input
                    type="text"
                    // name="permanentaddress"
                    name="address_street"
                    onChange={props.setValue}
                    required
                    defaultValue={props.userInfo.address_street}
                    className="form-control"
                  />
                </div>
                <div className="col-md-4">
                  <label> City </label>
                  <input
                    type="text"
                    name="address_city"
                    onChange={props.setValue}
                    required
                    defaultValue={props.userInfo.address_city}
                    className="form-control"
                  />
                </div>

                <div className="col-md-4">
                  <label> State </label>
                  <input
                    type="text"
                    name="address_state"
                    onChange={props.setValue}
                    required
                    defaultValue={props.userInfo.address_state}
                    className="form-control"
                  />
                </div>

                <div className="col-md-4">
                  <label> Country </label>
                  <input
                    type="text"
                    name="address_country"
                    onChange={props.setValue}
                    required
                    defaultValue={props.userInfo.address_country}
                    className="form-control"
                  />
                </div>
                <div className="col-md-4">
                  <label> Postal Code </label>
                  <input
                    type="text"
                    name="address_postalcode"
                    onChange={props.setValue}
                    required
                    defaultValue={props.userInfo.address_postalcode}
                    className="form-control"
                  />
                </div>
              </div>
            </fieldset>
          </div>
          <div>
            <label className="curr-addr">Current Address </label>
            <textarea
              type="text"
              name="localaddress"
              onChange={props.setValue}
              defaultValue={props.userInfo.localaddress}
              required
              className="form-control"
            ></textarea>
          </div>
        </div>
      </form>
    </div>
  );
}

function EmpEmergencyInfo(props) {
  return (
    <div className="emp-emg-info grid-dashlet">
      <form action="#" method="POST" onSubmit={props.updateEmergencyInfo}>
        <div className="emp-info-head">
          <span className="emp-info-head-title"> Emergency Contact</span>
          <button className="emp-info-head-edit" title="Update">
            <MdFileUpload /> <span className="update-txt"> Update </span>
          </button>
        </div>
        {/* {props.alertValue?.alertEmrText === "on" ? (
          <Alert alertText={props.alertValue} />
        ) : (
          ""
        )} */}
        <div className="emp-info-body">
          <div className="row">
            <div className="col-md-6">
              <label>Person Name</label>
              <input
                type="text"
                name="parent_name"
                defaultValue={props.userInfo.parent_name}
                required
                onChange={props.setEmergencyContactValue}
                className="form-control"
              />
            </div>
            <div className="col-md-6">
              <label>Relationship</label>
              <input
                name="parent_relationship"
                type="text"
                onChange={props.setEmergencyContactValue}
                defaultValue={props.userInfo.parent_relationship}
                required
                className="form-control"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>Phone</label>
              <input
                name="parent_telephone"
                onChange={props.setEmergencyContactValue}
                type="text"
                defaultValue={props.userInfo.parent_telephone}
                required
                className="form-control"
              />
            </div>
            <div className="col-md-6">
              <label>Personal Email</label>
              <input
                name="contact_email"
                onChange={props.setEmergencyContactValue}
                type="email"
                defaultValue={props.userInfo.contact_email}
                required
                className="form-control"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>Spouse Birthday </label>
              <input
                name="spouse_birthday"
                onChange={props.setEmergencyContactValue}
                type="date"
                defaultValue={props.userInfo.spouse_birthday}
                className="form-control"
              />
            </div>
            <div className="col-md-6">
              <label> Anniversary Date </label>
              <input
                name="anniversary_date"
                onChange={props.setEmergencyContactValue}
                type="date"
                defaultValue={props.userInfo.anniversary_date}
                className="form-control"
              />
            </div>
            <div>
              <label>Address </label>
              <textarea
                type="text"
                name="parent_address"
                onChange={props.setEmergencyContactValue}
                defaultValue={props.userInfo.parent_address}
                required
                className="form-control"
              ></textarea>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

function Personal(props) {
  const [personalInfo, setPersonalInfo] = useState({});
  const [emergencyContact, setEmergencyContact] = useState({});
  const [alertmsg, setAlertMsg] = useState({
    alertPerText: "off",
    alertEmrText: "off",
  });
  const [editPersonalInfo, setEditPersonalInfo] = useState({});
  const uId = localStorage.getItem("userId");
  var filesize = 0;
  const { loginData, setLoginData, updateData } =
    useContext(LoginManageContext);
  var userData = loginData.userData;

  // make array to skill set
  const convertInAssocArray = (array) => {

    var newSkillSetarray = new Array();
    for (var i = 0; i < array.length; i++) {
      if (array[i]) {
        var a = { label: array[i], value: array[i] };
        newSkillSetarray.push(a);
      }
    }
    return newSkillSetarray;
  };

  const setProficientSkills = (e) => {
    setEditPersonalInfo({
      ...editPersonalInfo,
      tech_skills_emp: e,
      personalInfo: true,
      uId: uId,
      update: false,
    });
  };

  const setAverageSkills = (e) => {
    setEditPersonalInfo({
      ...editPersonalInfo,
      tech_avg_skills: e,
      personalInfo: true,
      uId: uId,
      update: false,
    });
  };
  const setBeginnerSkills = (e) => {
    setEditPersonalInfo({
      ...editPersonalInfo,
      tech_beginner_skills: e,
      personalInfo: true,
      uId: uId,
      update: false,
    });
  };

  const setValue = (e) => {
    let img = e.target.files;

    if (img) {
      filesize = img[0].size;
      if (filesize > 757770) {
        alert("Please upload a file smaller than 700kb");
        return false;
      } else {
        let imageupload = URL.createObjectURL(img[0]);
        let files = e.target.files;
        let fileReader = new FileReader();
        fileReader.readAsDataURL(files[0]);
        fileReader.onload = (event) => {
          setEditPersonalInfo({
            ...editPersonalInfo,
            image: event.target.result,
          });
        };
      }
    }
    setEditPersonalInfo({
      ...editPersonalInfo,
      [e.target.name]: e.target.value,
      personalInfo: true,
      uId: uId,
      update: false,
    });
  };
  const setEmergencyContactValue = (e) => {
    setEmergencyContact({
      ...emergencyContact,
      [e.target.name]: e.target.value,
      emergencyContact: true,
      uId: uId,
    });
  };
  const validate = (string) => {
    var re = /^[A-Z a-z]+$/;
    if (re.test(string)) {
      return true; //alert('Valid Name.');
    } else {
      return false;
    }
  };

  // to update personal information
  const updatePerInfo = (e) => {
    e.preventDefault();
    //alert(file);
    // alert(editPersonalInfo.telephone);
    let panNo = editPersonalInfo.pan_no;
    const phoneNumber = editPersonalInfo.telephone;
    var isANumber = isNaN(phoneNumber) === false;
    var fullName = validate(editPersonalInfo.full_name);
    const email = editPersonalInfo.email;

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    // if(inputText.value.match(mailformat))
    // {
    if (email.match(mailformat) == false) {
      setAlertMsg({
        ...alertmsg,
        msg: "Please enter a valid personal email.",
        errorStatus: "error",
        alertPerText: "on",
        alertEmrText: "off",
      });
      // alert("please enter a valid personal Email ");
      return false;
    }
    if (fullName === false) {
      setAlertMsg({
        ...alertmsg,
        msg: "Please provide full name.",
        errorStatus: "error",
        alertPerText: "on",
        alertEmrText: "off",
      });
      // alert("please enter a valid full name ");
      return false;
    }
    if (isANumber) {
      if (phoneNumber.length != 10) {
        setAlertMsg({
          ...alertmsg,
          msg: "Please enter a valid phone number.",
          errorStatus: "error",
          alertPerText: "on",
          alertEmrText: "off",
        });
        // alert("please enter a valid no");
        return false;
      }
    } else {
      setAlertMsg({
        ...alertmsg,
        msg: "Please enter a valid phone number.",
        errorStatus: "error",
        alertPerText: "on",
        alertEmrText: "off",
      });
      // alert("please enter a valid no");
      return false;
    }
    if (filesize > 757770) {
      setAlertMsg({
        ...alertmsg,
        msg: "Please upload a file smaller than 700kb",
        errorStatus: "error",
        alertPerText: "on",
        alertEmrText: "off",
      });
      // alert("Please upload a file smaller than 700kb");
      return false;
    }


    if (panNo.length <10) {
      setAlertMsg({
        ...alertmsg,
        msg: "Please enter a valid pan number.",
        errorStatus: "error",
        alertPerText: "on",
        alertEmrText: "off",
      });
      // alert("please enter a valid no pan number");
      return false;
    }
    axios
      .post("/updateUserInfoApi.php", editPersonalInfo)
      .then(function (response) {
        const res = response.data;
        if (res.statusCode === 200) {
          localStorage.setItem("userinfo", JSON.stringify(res.result));
          updateData(res.result);
          setAlertMsg({
            ...alertmsg,
            msg: "Profile Updated Successfully!",
            errorStatus: "success",
            alertPerText: "on",
            alertEmrText: "off",
          });
          // alert("you have udated personal info successfully");
          setTimeout(() => {
            setAlertMsg(null);
          }, 3000);
        } else {
          setAlertMsg({
            ...alertmsg,
            msg: "Oops! something went wrong. Try again please.",
            errorStatus: "error",
            alertPerText: "on",
            alertEmrText: "off",
          });
          // alert("Oops something went wrong");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //

  // to update personal information
  const updateEmergencyInfo = (e) => {
    e.preventDefault();
    const parentName = validate(emergencyContact.parent_name);
    if (parentName === false) {
      setAlertMsg({
        ...alertmsg,
        msg: "Please provide a valid Person Name",
        errorStatus: "error",
        alertEmrText: "on",
        alertPerText: "off",
      });
      // alert("please enter a valid Person Name ");
      return false;
    }
    const phoneNumber = emergencyContact.parent_telephone;
    const parentRelationship = validate(emergencyContact.parent_relationship);

    if (parentRelationship === false) {
      setAlertMsg({
        ...alertmsg,
        msg: "Please provide a valid relationship.",
        errorStatus: "error",
        alertEmrText: "on",
        alertPerText: "off",
      });
      // alert("please enter a valid Relationship Name");
      return false;
    }
    var isANumber = isNaN(phoneNumber) === false;

    if (isANumber) {
      if (phoneNumber.length != 10) {
        setAlertMsg({
          ...alertmsg,
          msg: "Please provide a valid phone number.",
          errorStatus: "error",
          alertEmrText: "on",
          alertPerText: "off",
        });
        // alert("please enter a valid no");
        return false;
      }
    } else {
      setAlertMsg({
        ...alertmsg,
        msg: "Please provide a valid phone number.",
        errorStatus: "error",
        alertEmrText: "on",
        alertPerText: "off",
      });
      // alert("please enter a valid no");
      return false;
    }
    const email = emergencyContact.contact_email;
    if (validator.isEmail(email) == false) {
      setAlertMsg({
        ...alertmsg,
        msg: "Please provide a valid personal email.",
        errorStatus: "error",
        alertEmrText: "on",
        alertPerText: "off",
      });
      // alert("please enter a valid personal Email ");
      return false;
    }

    axios
      .post("/updateUserInfoApi.php", emergencyContact)
      .then(function (response) {
        const res = response.data;
        if (res.statusCode === 200) {
          localStorage.setItem("userinfo", JSON.stringify(res.result));
          updateData(res.result);
          setAlertMsg({
            ...alertmsg,
            msg: "Emergency Contact Updated Successfully!",
            errorStatus: "success",
            alertEmrText: "on",
            alertPerText: "off",
          });
          setTimeout(() => {
            setAlertMsg(null);
          }, 3000);
          // alert("you have udated personal info successfully");

          // window.location.href = "/dashboard";
        } else {
          setAlertMsg({
            ...alertmsg,
            msg: "Oops! Something went wrong. Try again.",
            errorStatus: "error",
            alertEmrText: "on",
            alertPerText: "off",
          });
          // alert("Oops! Something went wrong. Try again.");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    setEmergencyContact({
      ...emergencyContact,
      emergencyContact: true,
      parent_name: userData.parent_name,
      parent_relationship: userData.parent_relationship,
      parent_telephone: userData.parent_telephone,
      contact_email: userData.contact_email,
      spouse_birthday: userData.spouse_birthday,
      anniversary_date: userData.anniversary_date,
      parent_address: userData.parent_address,
      uId: uId,
    });
    setEditPersonalInfo({
      ...editPersonalInfo,
      personalInfo: true,
      full_name: userData.first_name + " " + userData.last_name,
      telephone: userData.telephone,
      pan_no: userData.pan_no,
      email: userData.email,
      date_of_birth: userData.u_dob,
      // permanentaddress: userData.permanentaddress,
      address_street: userData.address_street,
      address_state: userData.address_state,
      address_city: userData.address_city,
      address_country: userData.address_country,
      address_postalcode: userData.address_postalcode,
      localaddress: userData.localaddress,
      image: userData.image,
      u_doj: userData.u_doj,
      designation: userData.designation,
      team_name: userData.team_name,
      uId: uId,
      update: true,
      tech_skills_emp:
        userData.tech_skills_emp_c != null
          ? convertInAssocArray(
              userData.tech_skills_emp_c
                .replace(/[^a-zA-Z0-9]/g, " ")
                .trim()
                .replace(/\s+/g, ",")
                .split(",")
            )
          : "",
      tech_avg_skills:
        userData.tech_avg_skills_c != null
          ? convertInAssocArray(
              userData.tech_avg_skills_c
                .replace(/[^a-zA-Z0-9]/g, " ")
                .trim()
                .replace(/\s+/g, ",")
                .split(",")
            )
          : "",
      tech_beginner_skills:
        userData.tech_beginner_skills_c != null
          ? convertInAssocArray(
              userData.tech_beginner_skills_c
                .replace(/[^a-zA-Z0-9]/g, " ")
                .trim()
                .replace(/\s+/g, ",")
                .split(",")
            )
          : "",
    });
  }, []);
  return (
    <>
      <div className="main">
        <Sidebar />
        <div id="primary">
          <Header />
          <div id="emp-info" className="main-view">
            <div className="view-head">
              <h6> Personal Details </h6>
              <a
                target="_blank"
                href="https://nablasol.greythr.com/"
                className="nbl-btn"
                title="Click here to access your Payslips"
              >
                GreytHr
              </a>
              {/* <input type="submit" value="Update" /> */}
            </div>
            <div className="view-grid">
              <EmpInfo userInfo={userData} setValue={setValue} />
              {alertmsg?.msg&&<div className="successMsg">
                {alertmsg.alertPerText === "on" ? (
                    <Alert alertText={alertmsg} />
                  ) : (
                    <div>{alertmsg.msg}</div>
                  )}
                </div>}
              <EmpPersonalInfo
                alertValue={alertmsg}
                userInfo={userData}
                setValue={setValue}
                updatePerInfo={updatePerInfo}
                convertInAssocArray={convertInAssocArray}
                setProficientSkills={setProficientSkills}
                setAverageSkills={setAverageSkills}
                setBeginnerSkills={setBeginnerSkills}
              />
              <EmpEmergencyInfo
                alertValue={alertmsg}
                userInfo={userData}
                setEmergencyContactValue={setEmergencyContactValue}
                updateEmergencyInfo={updateEmergencyInfo}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Personal;
export { EmpInfo, EmpEmergencyInfo };
